import { z } from 'zod';

export const slackInfoSchema = z.object({
  teamId: z.string().nullable(),
  teamName: z.string().nullable(),
  chatBotEnabled: z.boolean(),
  taskSchedulerEnabled: z.boolean(),
  chatBotCosts: z.number().nullable(),
  chatBotTotalBudget: z.number().nullable(),
  chatBotRealBudget: z.number().nullable()
});

export type TSlackInfo = z.infer<typeof slackInfoSchema>;
