import { z } from 'nestjs-zod/z';

export const monthlyStatsSummarySegment = z.object({
  workingHours: z.number().positive(),
  reportedHours: z.number().nonnegative(),
  percent: z.number(),
  missingReportDays: z.number().nonnegative(),
});

export type TMonthlyStatsSummarySegment = z.infer<typeof monthlyStatsSummarySegment>;
