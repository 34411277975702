import { z } from 'nestjs-zod/z';

export const upsertTimeWorkEntryDataFormMobileSchema = z
  .object({
    date: z.string(),
    description: z.string(),
    type: z.string(),
    project: z.object({
      name: z.string(),
      id: z.string().min(1),
    }),
    reportedHours: z.union([z.number().min(0.25).max(24.0), z.string()]),
    descriptionOptionSetId: z.string().nullable(),
    hasPredefinedDescriptions: z.boolean(),
  })
  .refine(
    upsertTimeWorkData => {
      // if hasPredefinedDescriptions then selecting predefined description is a must
      if (upsertTimeWorkData.hasPredefinedDescriptions) {
        return upsertTimeWorkData.descriptionOptionSetId;
      }

      return true;
    },
    {
      message: 'Group can not be empty',
      path: ['descriptionOptionSetId'],
    },
  )
  .refine(
    upsertTimeWorkData => {
      // if not hasPredefinedDescriptions then 'normal' description is a must
      if (!upsertTimeWorkData.hasPredefinedDescriptions) {
        return upsertTimeWorkData.description;
      }

      return true;
    },
    {
      message: 'Comment can not be empty',
      path: ['description'],
    },
  );

export type TUpsertTimeWorkEntryDataFormMobile = z.infer<
  typeof upsertTimeWorkEntryDataFormMobileSchema
>;
