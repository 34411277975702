import { z } from 'nestjs-zod/z';
import { getTimeEntryDataSchema } from './get-time-entry-data.schema.type';

export const timeReportDaySchema = z.object({
  day: z.string(),
  holiday: z.string().optional(),
  holidays: z.string().array(),
  timeEntries: z.array(getTimeEntryDataSchema),
  canAddEntries: z.boolean(),
});

export type TTimeReportDay = z.infer<typeof timeReportDaySchema>;
