import { z } from 'zod';

export const chatBotHistorySchema = z.object({
  id: z.string(),
  userId: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  email: z.string(),
  amount: z.number(),
  date: z.string(),
});

export type TChatBotHistory = z.infer<typeof chatBotHistorySchema>;
