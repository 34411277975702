import { z } from 'nestjs-zod/z';

export const updateSubscriptionPreview = z.object({
    totalAmountToBeCharged: z.number(),
    nextPaymentTimestamp: z.number(),
    invoiceItems: z.array(z.object({
        description: z.string(),
        price: z.number()
    })),
    refundAvailable: z.boolean(),
    refundAmount: z.number()
});


export type TUpdateSubscriptionPreview = z.infer<typeof updateSubscriptionPreview>;
