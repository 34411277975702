import { z } from 'nestjs-zod/z';

export const getUserPlanSchema = z.object({
  type: z.union([z.literal('SUBSCRIPTION'), z.literal('ONE_TIME_PAYMENT')]),
  paymentStatus: z.union([
    z.literal('CREATED'),
    z.literal('FAILED'),
    z.literal('CANCELED'),
    z.literal('EXPIRED'),
    z.literal('COMPLETED'),
  ]),
  subscriptionId: z.nullable(z.string()),
  expiresAt: z.nullable(z.string()),
  renewedAt: z.nullable(z.string()),
  createdAt: z.string(),
});

export type TGetUserPlan = z.infer<typeof getUserPlanSchema>;
