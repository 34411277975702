import { z } from 'nestjs-zod/z';

export const userProjectStats = z.object({
  projectId: z.string(),
  projectName: z.string(),
  reportedHours: z.number().positive(),
  billableReportedHours: z.number().positive(),
});

export const userProjectStatsSummary = z.object({
  projects: z.array(userProjectStats),
  totalHours: z.number().positive(),
  billableTotalHours: z.number().positive(),
});

export type TUserProjectsStats = z.infer<typeof userProjectStats>;
export type TUserProjectsStatsSummary = z.infer<typeof userProjectStatsSummary>;
