import { z } from 'nestjs-zod/z';
import { ContractType } from '../users';
import { TimeEntryType } from './types';

export const getTimeEntryActivityDataSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.nativeEnum(TimeEntryType),
  reportedTimeRatio: z.number(),
  billableReportedTimeRatio: z.number(),
  contractTypes: z.array(z.nativeEnum(ContractType)),
  isArchived: z.boolean(),
});

export type TGetTimeEntryActivityData = z.infer<typeof getTimeEntryActivityDataSchema>;
