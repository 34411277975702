import { z } from 'nestjs-zod/z';
import { TimeEntryType } from './types';
import { ProjectCategory } from '../project';

export const timeEntryListItemSchema = z.object({
  projectType: z.nativeEnum(TimeEntryType),
  totalReportedTime: z.number().nonnegative(),
  totalBillableTime: z.number().nonnegative(),
});

export const timeEntryProjectCategoryItemSchema = z.object({
  projectCategory: z.nativeEnum(ProjectCategory),
  totalReportedTime: z.number().nonnegative(),
  totalBillableTime: z.number().nonnegative(),
});

export const descriptionOptionSetItemSchema = z.object({
  descriptionOptionSet: z.string(),
  totalReportedTime: z.number().nonnegative(),
  totalBillableTime: z.number().nonnegative(),
});

export type TTimeEntryListItem = z.infer<typeof timeEntryListItemSchema>;
export type TTimeEntryProjectCategoryListItem = z.infer<typeof timeEntryProjectCategoryItemSchema>;
export type TDescriptionOptionSetItem = z.infer<typeof descriptionOptionSetItemSchema>;

export const timeEntriesStatistics = z.object({
  list: z.array(timeEntryListItemSchema),
  projectCategoryList: z.array(timeEntryProjectCategoryItemSchema),
  descriptionOptionSetList: z.array(descriptionOptionSetItemSchema),
  totalReportedTime: z.number().nonnegative(),
  totalBillableTime: z.number().nonnegative(),
});

export type TTimeEntriesStatistics = z.infer<typeof timeEntriesStatistics>;
