export class AppConfig {
  get cognitoConfig() {
    return {
      cognitoRegion: this.getOrThrow('COGNITO_REGION'),
      cognitoUserPoolId: this.getOrThrow('COGNITO_USER_POOL_ID'),
      cognitoUserPoolWebClientId: this.getOrThrow('COGNITO_USER_POOL_WEB_CLIENT_ID'),
      cognitoOAuthDomain: this.getOrThrow('COGNITO_OAUTH_DOMAIN'),
      cognitoOAuthRedirectSignIn: this.getOrThrow('COGNITO_OAUTH_REDIRECT_SIGNIN'),
      cognitoOAuthRedirectSignOut: this.getOrThrow('COGNITO_OAUTH_REDIRECT_SIGNOUT'),
    };
  }

  get captchaConfig() {
    return { captchaPublicKey: this.getOrThrow('NEXT_PUBLIC_CAPTCHA_SITE_KEY') };
  }

  get googleConfig() {
    return {
      googleClientId: this.getOrThrow('NEXT_PUBLIC_GOOGLE_CLIENT_ID'),
      gtmContainerId: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID ?? '',
    };
  }

  get config() {
    return {
      cognitoConfig: this.cognitoConfig,
      captchaConfig: this.captchaConfig,
      googleConfig: this.googleConfig,
    };
  }

  private getOrThrow(key: string) {
    const env = process.env[key];
    if (!env) throw new Error(`Env ${key} not found.`);
    return env;
  }
}
