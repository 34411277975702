import { z } from 'nestjs-zod/z';

const externalLinkSchema = z.object({
  id: z.string(),
  logo: z.string().nullable(),
  text: z.string(),
  url: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const externalLinkUpsertSchema = z.object({
  logo: z.string().optional(),
  text: z.string(),
  url: z.string(),
});

export const externalLinkPatchSchema = z.object({
  logo: z.string().optional(),
  text: z.string(),
  url: z.string(),
});

export type TExternalLink = z.infer<typeof externalLinkSchema>;
export type TExternalLinkUpsert = z.infer<typeof externalLinkUpsertSchema>;
export type TExternalLinkPatch = z.infer<typeof externalLinkPatchSchema>;
