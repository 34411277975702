export type UserId = string & { readonly _type: unique symbol };

export enum ContractType {
  B2B = 'B2B',
  UoP = 'UOP',
  UoD = 'UOD',
  UZ = 'UZ',
  External = 'EXTERNAL',
}

export enum UserStatus {
  Archived = 'ARCHIVED',
  Active = 'ACTIVE',
}

export enum UserRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  ExternalManager = 'EXTERNAL_MANAGER',
  ExternalUser = 'EXTERNAL_USER',
  User = 'USER',
  Root = 'ROOT',
}
export enum Department {
  CLevel = 'C_LEVEL',
  Office = 'OFFICE',
  HR = 'HR',
  Design = 'DESIGN',
  BD = 'BD',
  Marketing = 'MARKETING',
  Delivery = 'DELIVERY',
  Development = 'DEVELOPMENT',
  Other = 'OTHER',
}

export enum Team {
  ElChurros = 'EL_CHURROS',
  LosMacacos = 'LOS_MACACOS',
  LasQuokkas = 'LAS_QUOKKAS',
  FinxS = 'FINXS',
  ElTacos = 'EL_TACOS',
  ElSombreros = 'EL_SOMBREROS',
  Delivery = 'DELIVERY',
  Operations = 'OPERATIONS',
  Revenue = 'REVENUE',
}
