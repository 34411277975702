import { z } from 'nestjs-zod/z';

export const extendedUserSchema = z.object({
  cognitoId: z.string(),
  email: z.string(),
  firstName: z.nullable(z.string()),
  lastName: z.nullable(z.string()),
  avatarUrl: z.nullable(z.string()),
  description: z.nullable(z.string()),
  isActive: z.boolean(),
});

export type TExtendedUser = z.infer<typeof extendedUserSchema>;
