import { z } from 'nestjs-zod/z';
import { passwordValidator } from '../validators/password';

export const confirmForgotPasswordSchema = z
  .object({
    code: z.string().refine(value => value.length === 6, {
      message: 'Code is invalid',
    }),
    newPassword: passwordValidator,
    repeatNewPassword: passwordValidator,
  })
  .refine(data => data.newPassword === data.repeatNewPassword, {
    message: 'New passwords must match',
    path: ['repeatNewPassword'],
  });

export type TConfirmPassword = z.infer<typeof confirmForgotPasswordSchema>;
