import { z } from 'nestjs-zod/z';
import sanitizeHtml from 'sanitize-html';

export const addressFormExampleSchema = z.object({
  street: z
    .string()
    .nonempty('You have to provide the street name')
    .min(2, { message: 'Invalid street name' })
    .transform(value => sanitizeHtml(value)),
  city: z
    .string()
    .nonempty('You have to provide the city')
    .min(2, { message: 'Invalid city' })
    .transform(value => sanitizeHtml(value)),
  state: z
    .string()
    .nonempty('You have to provide the state')
    .min(2, { message: 'Invalid state' })
    .transform(value => sanitizeHtml(value)),
  country: z
    .string()
    .nonempty('You have to provide the country')
    .min(2, { message: 'Invalid country' })
    .transform(value => sanitizeHtml(value)),
  postalCode: z
    .string()
    .nonempty('You have to provide the postal code')
    .min(5, { message: 'Invalid postal code' })
    .transform(value => sanitizeHtml(value)),
});

export type TAddressFormExample = z.infer<typeof addressFormExampleSchema>;
