import { z } from 'nestjs-zod/z';
import { ProjectCategory, ProjectStatus } from './types';
import { descriptionOptionSetSchema } from '../project/get-project-data.schema.type';

export const projectListItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  category: z.nativeEnum(ProjectCategory),
  status: z.nativeEnum(ProjectStatus),
  descriptionOptionSet: descriptionOptionSetSchema.optional().nullable(),
  favorite: z.boolean(),
});

export type TProjectListItem = z.infer<typeof projectListItemSchema>;
