const captchaApiUrl = 'https://www.google.com/recaptcha/api/siteverify';

export interface IReCaptchaResponse {
  success: boolean;
  challenge_ts: string;
  hostname: string;
  score: number;
  action: string;
}

interface IValidateCaptchaScore {
  code: string;
  secret: string;
}

export async function validateCaptchaScore(data: IValidateCaptchaScore) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `secret=${data.secret}&response=${data.code}`,
  };

  const response: IReCaptchaResponse = await fetch(captchaApiUrl, options).then(reCaptchaRes =>
    reCaptchaRes.json(),
  );

  return response;
}

export const captchaFailResponse = {
  status: 'failure',
  message: 'Captcha verification failed, please try again.',
};

export const userNotAuthorizedResponse = {
  status: 'failure',
  message: 'Please log in to apply for job position',
};

export const captchaRegistrationScoreReq = 0.5;
export const captchaApplicationScoreReq = 0.5;
export const headerKey = 'x-captcha';

export default validateCaptchaScore;
