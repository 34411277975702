import validateCaptchaScore, {
  captchaFailResponse,
  captchaRegistrationScoreReq,
  headerKey,
} from './utils';

import { NextApiRequest, NextApiResponse } from 'next';

interface IRequestWithCaptcha {
  req: NextApiRequest;
  res: NextApiResponse;
  captchaSecret: string;
  successCallback: () => Promise<unknown>;
}

export async function requestWithCaptcha({
  req,
  res,
  captchaSecret,
  successCallback,
}: IRequestWithCaptcha) {
  try {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { success, score } = await validateCaptchaScore({
      code: req.headers[headerKey] as string,
      secret: captchaSecret,
    });

    if (success && score > captchaRegistrationScoreReq) {
      await successCallback();
    } else {
      res.status(422).json(captchaFailResponse);
    }
  } catch (err) {
    res.status(405).json({
      status: 'failure',
      message: JSON.stringify(err),
    });
  }
}
