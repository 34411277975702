import { Ability, AbilityBuilder, AbilityClass, Subject } from '@casl/ability';
import { Actions, type AppAbility as AuthAppAbility } from '@hub/authorization';
import { IRuleDto } from '@hub/contracts';

export const AppAbility = Ability as AbilityClass<AuthAppAbility>;

export default function defineRulesFor(abilities: IRuleDto[]) {
  if (!abilities) return;

  const { can, rules, cannot } = new AbilityBuilder(AppAbility);

  for (const ability of abilities) {
    const action = ability.action as Actions;
    const subject = ability.subject as Subject;
    const conditions = ability.conditions;
    if (!ability.inverted) can(action, subject, conditions);
    else cannot(action, subject, conditions);
  }

  return rules;
}

export function buildAbilityFor(abilities: IRuleDto[]): AuthAppAbility {
  return new AppAbility(defineRulesFor(abilities));
}
