import {
  ETypeOfWorkKey,
  TMonthlyStatsSummarySegment,
  TMonthlyStatsUnpaidVacationSegment,
  TMonthlyReportedHoursStats,
  TMostTrackedActivitiesSegment,
  TTypeOfWorkSegment,
} from '.';
import { ProjectCategory } from '../project';
import { TimeEntryType } from '../time-entry';

export const monthlyStatsSummary: TMonthlyStatsSummarySegment = {
  workingHours: 168,
  reportedHours: 49.5,
  percent: (49.5 / 168) * 100,
  missingReportDays: 15,
};

export const monthlyStatsWork: TMonthlyReportedHoursStats = {
  workingHours: 168,
  reportedHoursTypes: [ // @TODO: H20-1294
    {
      reportType: TimeEntryType.WORK,
      value: 76.5,
      unit: 'hrs',
      percent: 80,
    },
    {
      reportType: TimeEntryType.OFF_PAID,
      value: 20.0,
      unit: 'hrs',
      percent: 20,
    },
    {
      reportType: TimeEntryType.OFF_UNPAID,
      value: 6.5,
      unit: 'hrs',
      percent: 20,
    },
    {
      reportType: TimeEntryType.OFF_SICK,
      value: 4.0,
      unit: 'hrs',
      percent: 2,
    },
    {
      reportType: TimeEntryType.OFF_CONFERENCE,
      value: 0.0,
      unit: 'hrs',
      percent: 0,
    },
  ],
};

export const monthlyStatsUnpaidVacation: TMonthlyStatsUnpaidVacationSegment = {
  unpaidVacation: 30,
  workingHours: 168,
  percent: (30 / 168) * 100,
};

export const typeOFWorkStats: TTypeOfWorkSegment[] = [
  {
    key: 'COMMERCIAL' as ETypeOfWorkKey,
    value: 80,
    unit: '%',
    percent: 80,
  },
  {
    key: 'INTERNAL' as ETypeOfWorkKey,
    value: 20,
    unit: '%',
    percent: 20,
  },
];

export const mostTrackedActivitiesStats: TMostTrackedActivitiesSegment[] = [
  {
    project: {
      id: 'project_1',
      name: 'Project 1',
      type: ProjectCategory.Commercial,
    },
    records: 46,
    reportedHours: 72.5,
  },
  {
    project: {
      id: 'project_2',
      name: 'Project 2',
      type: ProjectCategory.Internal,
    },
    records: 15,
    reportedHours: 28,
  },
];
