export enum FormsNames {
    UserProfilesForm = 'user_profiles_form',
}

export const FormsFieldsSet = {
    [FormsNames.UserProfilesForm]: {
        Department: 'Department',
        SeniorityLevel: 'Seniorty Level',
        Team: 'Team'
    }
}