import { z } from 'nestjs-zod/z';

export const getUserSchema = z.object({
  cognitoId: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  avatarKey: z.string(),
  description: z.string(),
});

export type TGetUser = z.infer<typeof getUserSchema>;
