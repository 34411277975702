import { z } from 'zod';
import { periodSchema } from './period';
import { BillingPeriodStatus } from './types';

export const periodListItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  period: periodSchema,
  status: z.nativeEnum(BillingPeriodStatus),
  numberOfApprovedUsers: z.number(),
});

export type TPeriodListItem = z.infer<typeof periodListItemSchema>;
