import { ContractType, TimeEntry } from '@prisma/client';

export type TimeEntryId = string & { readonly __type: unique symbol };
export type TimeEntryActivityId = string & { readonly __type: unique symbol };

export enum TimeEntryType { // @TODO: H20-1294
  WORK = 'WORK',
  OFF_PAID = 'OFF_PAID',
  OFF_UNPAID = 'OFF_UNPAID',
  OFF_CONFERENCE = 'OFF_CONFERENCE',
  OFF_SICK = 'OFF_SICK',
}

export type UserTimeEntryData = {
  cognitoId: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
};

export type TimeEntryWithCSVData = TimeEntry & {
  project: { id: string; name: string; category: string } | null;
  user: UserTimeEntryData;
  descriptionOptionSet: {
    id: string;
    option: string;
  } | null;
};

export type TimeEntryWithUserAndProject = TimeEntry & {
  project: { id: string; name: string; category: string } | null;
  user: UserTimeEntryData;
};

export type TimeOffType = // @TODO: H20-1294
  | TimeEntryType.OFF_PAID
  | TimeEntryType.OFF_SICK
  | TimeEntryType.OFF_UNPAID
  | TimeEntryType.OFF_CONFERENCE;

export type TimeEntriesDataForInvoice = TimeEntryWithUserAndProject & {
  user: UserTimeEntryData & {
    profile: {
      contractType: ContractType;
      department: string;
      seniorityLevel: string | null;
    } | null;
  };
};
