import { z } from 'nestjs-zod/z';
import { TimeEntryType } from '../time-entry';

export const reportedHoursType = z.object({
  reportType: z.enum([ // @TODO: H20-1294
    TimeEntryType.WORK,
    TimeEntryType.OFF_UNPAID,
    TimeEntryType.OFF_SICK,
    TimeEntryType.OFF_PAID,
    TimeEntryType.OFF_CONFERENCE,
  ]),
  value: z.number().nonnegative(),
  unit: z.string(),
  percent: z.number().nonnegative(),
});

export const monthlyStatsWorkSegment = z.object({
  workingHours: z.number().nonnegative(),
  reportedHoursTypes: z.array(reportedHoursType),
});

export type TMonthlyReportedHoursStats = z.infer<typeof monthlyStatsWorkSegment>;
export type TReportedHoursType = z.infer<typeof reportedHoursType>;
