import { z } from 'zod';

export const calendarDaySchema = z.object({
  id: z.string(),
  calendarId: z.string(),
  name: z.string(),
  date: z.string(),
});

export type TCalendarDay = z.infer<typeof calendarDaySchema>;
