import { z } from 'nestjs-zod/z';
const Name = z.object({
  language: z.string(),
  text: z.string(),
});

const Subdivision = z.object({
  code: z.string(),
  shortName: z.string(),
});

const holidayTypes = ['Public', 'Bank', 'School', 'BackToSchool', 'EndOfLesons'] as const;

const Holiday = z.object({
  startDate: z.string(),
  endDate: z.string(),
  name: z.array(Name),
});

export const holidayData = z.array(Holiday);

export type THolidayDataItem = z.infer<typeof Holiday>;
export type THolidayData = z.infer<typeof holidayData>;
