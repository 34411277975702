import { z } from 'nestjs-zod/z';
import { MAX_REPORTED_MINUTES } from './max-reported-minutes';

export const upsertPersonalTimeWorkEntryDataSchema = z.object({
  date: z.union([z.date(), z.string()]),
  reportedMinutes: z.number().positive().multipleOf(0.25).max(MAX_REPORTED_MINUTES),
  description: z.string(),
  projectId: z.string().uuid(),
  createdAt: z.union([z.date(), z.string()]).optional(),
  updatedAt: z.union([z.date(), z.string()]).optional(),
  descriptionOptionSetId: z.string().nullable(),
});

upsertPersonalTimeWorkEntryDataSchema.refine(
  upsertTimeWorkData => {
    return !upsertTimeWorkData.descriptionOptionSetId && !upsertTimeWorkData.description;
  },
  {
    message: 'Description can not be empty',
  },
);

export type TUpsertPersonalTimeWorkEntryData = z.infer<
  typeof upsertPersonalTimeWorkEntryDataSchema
>;
