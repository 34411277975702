import { z } from 'nestjs-zod/z';
import { UserRole, UserStatus } from './types';
import { userContractSchema } from './user-contract.schema.type';

export const assignedProjectSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
});

export type TAssignedProject = z.infer<typeof assignedProjectSchema>;

export const getUserDataSchema = z.object({
  id: z.string().uuid(),
  email: z.string().email().nonempty(),
  firstName: z.string().nonempty(),
  lastName: z.string().nonempty(),
  role: z.nativeEnum(UserRole),
  status: z.nativeEnum(UserStatus),
  contract: userContractSchema.nullable(),
  assignedProjects: z.array(assignedProjectSchema),
  seniorityLevel: z.string().optional().nullable(),
  team: z.string().optional().nullable(),
  customExternalId: z.string().nullable()
});

export type TGetUserDataDTO = z.infer<typeof getUserDataSchema>;
