import { z } from 'zod';

export const calendarWithUsersSchema = z.object({
  id: z.string(),
  name: z.string(),
  users: z.array(
    z.object({
      id: z.string(),
      firstName: z.string(),
      lastName: z.string(),
    }),
  ),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type TCalendarWithUsers = z.infer<typeof calendarWithUsersSchema>;
