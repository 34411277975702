import { AuthProvider } from '@hub/cognito-auth/AuthContext';
import { CognitoApi } from '@hub/cognito-auth/CognitoApi/CognitoApi';
import { IRuleDto, TMe } from '@hub/contracts';
import { createHttpClient } from '@hub/http-client';
import { LoggerProvider, getEnvVariables } from '@hub/next-utils';
import { UserApi } from '@hub/shared-apis';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { GoogleTagManager } from '@next/third-parties/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import 'rc-tooltip/assets/bootstrap.css';
import { useEffect, useState } from 'react';
import 'react-modern-drawer/dist/index.css';
import { defaultLocale, loadCatalog } from '../i18n';
import { AppConfig } from '../modules/AppConfig/AppConfig';
import { AppConfigProvider } from '../modules/AppConfig/AppConfigProvider';
import { AbilityProvider } from '../modules/Auth';
import { Toaster } from '../modules/Toast';
import '../styles/globals.css';
import { NextPageWithLayout, routes } from '../utils';

type PageProps = {
  currentUser?: TMe;
  currentUserAbilities?: IRuleDto[];
  dehydratedState?: unknown;
};

type CustomAppProps = {
  config: AppConfig;
  Component: NextPageWithLayout<PageProps>;
} & AppProps<PageProps>;


const MyApp = ({ Component, pageProps, router, config }: CustomAppProps) => {
  const currentUser = pageProps?.currentUser || null;
  const currentUserAbilities = pageProps?.currentUserAbilities || null;

  const { cognitoConfig, googleConfig } = config;

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            networkMode: 'always',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            retry: (failureCount, error: any) => {
              if (error?.response.status == 502 && failureCount > 3) return false;
              if (error?.response.status >= 400) return false;
              return true;
            },
            staleTime: 15000,
          },
          mutations: {
            networkMode: 'always',
          },
        },
      }),
  );

  const locale = router.locale;

  const [shortLocale] = locale ? locale.split('-') : [defaultLocale];

  useEffect(() => {
    loadCatalog(shortLocale);
  }, [shortLocale]);

  const getLayout = Component.getLayout ?? (page => page);
  const cognitoApi = new CognitoApi(cognitoConfig);

  return (
    <>
      <Head>
        <title>EL Chrono</title>
      </Head>
      <GoogleTagManager gtmId={config.googleConfig.gtmContainerId} />

      <AppConfigProvider config={config}>
        <GoogleOAuthProvider clientId={googleConfig.googleClientId}>
          <LoggerProvider>
            <QueryClientProvider client={queryClient}>
              <AuthProvider
                ssrUser={currentUser}
                currentUserAbilities={currentUserAbilities}
                cognitoApi={cognitoApi}
                authApi={new UserApi(createHttpClient({ url: getEnvVariables().PAGE_URL }))}
                onSignOut={() => router.push(routes.login)}
              >
                <AbilityProvider>
                  <Hydrate state={pageProps.dehydratedState}>
                    <I18nProvider i18n={i18n}>
                      <div>
                        {Toaster}
                        {getLayout(<Component {...pageProps} />)}
                        <div id="portal" />
                      </div>
                    </I18nProvider>
                  </Hydrate>
                </AbilityProvider>
              </AuthProvider>
            </QueryClientProvider>
          </LoggerProvider>
        </GoogleOAuthProvider>
      </AppConfigProvider>
    </>
  );
};

MyApp.getInitialProps = async (ctx: AppContext) => {
  const appProps = await App.getInitialProps(ctx);

  const appConfig = new AppConfig();
  const { config, cognitoConfig } = appConfig;

  // We need configure Amplify first time at the top of app - before
  // withSession and Providers
  CognitoApi.configureAmplify(cognitoConfig)
  return { ...appProps, config };
};

export default MyApp;
