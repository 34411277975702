export enum PaymentsAction {
  READ = 'read',
  MANAGE = 'manage',
}

export enum PaymentSubject {
  SUBJECT = 'Payment',
}

export type PaymentClaims = [PaymentsAction, 'Payment'];
