import { z } from 'zod';
import { FromBeforeToOrSameDayRefine, IsoDateStringToDateString, refineDateString } from '../utils';

export const periodSchema = z
  .object({
    from: z
      .string()
      .transform(IsoDateStringToDateString)
      .refine(value => refineDateString(value)),
    to: z
      .string()
      .transform(IsoDateStringToDateString)
      .refine(value => refineDateString(value)),
  })
  .refine(FromBeforeToOrSameDayRefine, {
    message: 'Period must end after it starts!',
    path: ['to'],
  })
  .refine(FromBeforeToOrSameDayRefine, {
    message: 'Period must start before it ends!',
    path: ['from'],
  });

export type TPeriod = z.infer<typeof periodSchema>;

export const updateEmployeeTimePeriodCommentSchema = z.object({
  comment: z.string(),
});

export type TUpdateEmployeeTimePeriodComment = z.infer<
  typeof updateEmployeeTimePeriodCommentSchema
>;
