import { z } from 'nestjs-zod/z';
import { ContractType, UserRole, UserStatus } from './types';

export const userProfileSchema = z.object({
  id: z.string().uuid(),
  contractType: z.nativeEnum(ContractType),
  role: z.nativeEnum(UserRole),
  status: z.nativeEnum(UserStatus),
});

export type TUserProfile = z.infer<typeof userProfileSchema>;
