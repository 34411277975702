import { deleteAllCookies } from '@hub/next-utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Hub } from 'aws-amplify/utils';
import axios from 'axios';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect } from 'react';
import { AuthProviderProps, IAuthApi, IAuthContext, IAuthUser } from './Auth.interface';

export const AuthContext = createContext<IAuthContext>({
  cognitoApi: undefined,
  currentUser: null,
  currentUserAbilities: null,
  setCurrentAuthenticatedUser: () => Promise.reject('Not implemented!'),
  signOut: () => {
    throw new Error('Not implemented!');
  },
});

const currentUserQueryKey = ['auth', 'me'];

const useCurrentUser = (authApi: IAuthApi, { initialData }: { initialData: IAuthUser | null }) => {
  const queryClient = useQueryClient();

  const { data: currentUser, refetch: refetchCurrentUser } = useQuery<IAuthUser | null, Error>(
    currentUserQueryKey,
    () => authApi.getCurrentUser().catch(() => null),
    {
      initialData,
    },
  );

  const setCurrentAuthenticatedUser = useCallback(async () => {
    await refetchCurrentUser();
  }, [refetchCurrentUser]);

  const clearCurrentAuthenticatedUser = useCallback(
    () => queryClient.setQueryData(currentUserQueryKey, null),
    [refetchCurrentUser],
  );

  return {
    currentUser: currentUser,
    setCurrentAuthenticatedUser,
    clearCurrentAuthenticatedUser,
  };
};

export const AuthProvider = ({
  cognitoApi,
  ssrUser,
  currentUserAbilities,
  authApi,
  onSignOut,
  children,
}: PropsWithChildren<AuthProviderProps>) => {
  const { currentUser, setCurrentAuthenticatedUser, clearCurrentAuthenticatedUser } =
    useCurrentUser(authApi, { initialData: ssrUser });

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedOut':
          clearCurrentAuthenticatedUser();
      }
    });
  }, []);

  const signOut = useCallback(() => {
    deleteAllCookies();
    cognitoApi
      .signOut()
      .then(() => {
        localStorage.clear();
        axios.post('/api/logout');
        onSignOut();
      })
      .catch(() => console.error('Sth went wrong during logout'));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        cognitoApi,
        currentUser: currentUser || ssrUser,
        currentUserAbilities,
        setCurrentAuthenticatedUser,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  if (context.currentUser) {
    const { firstName, lastName } = context.currentUser;

    const fullName =
      firstName || lastName ? `${firstName || ''} ${lastName || ''}`.trim() : undefined;

    context.currentUser.avatarUrl = context.currentUser?.avatarUrl || '';

    context.currentUser.fullName = fullName;
  }

  return context;
};
