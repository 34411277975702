import { z } from 'nestjs-zod/z';
import { ProjectCategory } from '../project';

export const mostTrackedActivitiesSegment = z.object({
  project: z.object({
    id: z.string(),
    name: z.string(),
    type: z.nativeEnum(ProjectCategory),
  }),
  records: z.number().positive(),
  reportedHours: z.number().positive(),
});

export type TMostTrackedActivitiesSegment = z.infer<typeof mostTrackedActivitiesSegment>;
