import { z } from 'nestjs-zod/z';

export const upsertClientDataSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: 'You have to provide client name' }),
  invoicingData: z.string().optional(),
  contactPerson: z.string().optional(),
  contactInformation: z.string().optional(),
  accountManager: z.string().nullable().optional(),
  isArchived: z.boolean().default(false),
});

export type TUpsertClientData = z.infer<typeof upsertClientDataSchema>;
