import { Amplify, ResourcesConfig } from 'aws-amplify';
import { AuthUser, confirmResetPassword, confirmSignIn, confirmSignUp, getCurrentUser, resendSignUpCode, resetPassword, signIn, signOut, updatePassword } from 'aws-amplify/auth';
import { IAuthEnvs } from '../Auth.interface';

export class CognitoApi {
  constructor(readonly env: IAuthEnvs) {
    CognitoApi.configureAmplify(env);
  }

  static getAmplifyConfig(env: IAuthEnvs): ResourcesConfig {
    const {
      cognitoUserPoolId,
      cognitoUserPoolWebClientId,
      cognitoOAuthDomain,
      cognitoOAuthRedirectSignIn,
      cognitoOAuthRedirectSignOut,
    } = env;

    return {
      Auth: {
        Cognito: {
          userPoolId: cognitoUserPoolId,
          userPoolClientId: cognitoUserPoolWebClientId,
          loginWith: {
            oauth: {
              domain: cognitoOAuthDomain,
              redirectSignIn: [cognitoOAuthRedirectSignIn],
              redirectSignOut: [cognitoOAuthRedirectSignOut],
              responseType: 'code',
              scopes: ['email', 'profile', 'openid']
            }
          }
        },
      },
    }
  }

  static configureAmplify(env: IAuthEnvs) {
    const config = CognitoApi.getAmplifyConfig(env);
    Amplify.configure(config, { ssr: true });
  }

  // TODO: check return type:
  // https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#signin
  async signIn(email: string, password: string): Promise<boolean> {
    const { isSignedIn } = await signIn({ username: email, password })
    return isSignedIn
  }

  async signInWithoutPassword(email: string): Promise<boolean> {
    const { isSignedIn, nextStep } = await signIn({ username: email, options: { authFlowType: 'CUSTOM_WITHOUT_SRP' } });
    return isSignedIn || nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
  }

  async sendCustomChallengeAnswer(answer: string): Promise<boolean> {
    const { isSignedIn } = await confirmSignIn({ challengeResponse: answer });
    return isSignedIn
  }

  async confirmUser(email: string, code: string): Promise<boolean> {
    const { isSignUpComplete } = await confirmSignUp({ username: email, confirmationCode: code });
    return isSignUpComplete
  }

  async resendConfirmationCode(email: string): Promise<void> {
    await resendSignUpCode({ username: email });
  }

  async signOut(): Promise<void> {
    await signOut();
  }

  forgotPassword(email: string): Promise<unknown> {
    return resetPassword({ username: email });
  }

  forgotPasswordSubmit(email: string, password: string, code: string): Promise<void> {
    return confirmResetPassword({ username: email, confirmationCode: code, newPassword: password });
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return updatePassword({ newPassword, oldPassword });
  }

  protected getCurrentAuthenticatedRawCognitoUser(bypassCache = false): Promise<AuthUser> {
    return getCurrentUser()
  }
}
