import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IHttpClient } from './cognito-auth.types';

export type TAxiosErrorInterceptor = (error: AxiosError) => AxiosError;

export interface IHttpClientHeaderOptions {
  accessToken?: string;
  tenantId?: string;
}

export interface IHttpClientOptions {
  url: string;
}

export class BasicHttpClient implements IHttpClient {
  private static instance: BasicHttpClient;
  private readonly client: AxiosInstance;
  private readonly options: IHttpClientOptions;

  constructor(options: IHttpClientOptions) {
    this.options = options;
    this.client = axios.create({
      baseURL: this.options.url,
    });
  }

  getToken(): string | undefined {
    throw new Error('Method not implemented.');
  }

  public static getInstance(options: IHttpClientOptions): BasicHttpClient {
    if (!BasicHttpClient.instance) {
      BasicHttpClient.instance = new BasicHttpClient(options);
    }
    return BasicHttpClient.instance;
  }

  async get<TReturnType>(url: string, cfg: AxiosRequestConfig): Promise<TReturnType> {
    const { data } = await this.client.get<TReturnType>(url, {
      ...cfg,
    });
    return data;
  }

  async post<TRequestDataType, TReturnType = void>(
    url: string,
    requestData: TRequestDataType,
    config?: AxiosRequestConfig,
  ): Promise<TReturnType> {
    const { data } = await this.client.post<TRequestDataType, AxiosResponse<TReturnType>>(
      url,
      requestData,
      config,
    );
    return data;
  }

  async put<TRequestDataType, TReturnType = void>(
    url: string,
    requestData: TRequestDataType,
    config?: AxiosRequestConfig,
  ): Promise<TReturnType> {
    const { data } = await this.client.put<TRequestDataType, AxiosRequestConfig>(
      url,
      requestData,
      config,
    );

    return data;
  }

  async patch<TRequestDataType, TReturnType = void>(
    url: string,
    requestData: TRequestDataType,
    config?: AxiosRequestConfig,
  ): Promise<TReturnType> {
    const { data } = await this.client.patch<TRequestDataType, AxiosResponse<TReturnType>>(
      url,
      requestData,
      config,
    );
    return data;
  }

  async delete<TRequestDataType, TReturnType = void>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<TReturnType> {
    const { data } = await this.client.delete<TRequestDataType, AxiosResponse<TReturnType>>(
      url,
      config,
    );

    return data;
  }

  static getHeaders(options: IHttpClientHeaderOptions): AxiosRequestConfig {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        // 'X-Request-Id': uuid4(),
        ...(options.accessToken ? { Authorization: `Bearer ${options.accessToken}` } : {}),
        ...(options.tenantId ? { 'x-tenant-id': `${options.tenantId}` } : {}),
      },
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}
