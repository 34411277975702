import { AutoGeneratedId } from '../id';
import { TGetUserTenants } from './get-user-tenants.schema.type';

export class GetUserTenantsDtoBuilder {
  private constructor(private dto: TGetUserTenants) {}

  static prefilled(): GetUserTenantsDtoBuilder {
    return new GetUserTenantsDtoBuilder({
      id: AutoGeneratedId(),
      name: 'Marvel',
      logoUrl: null,
    });
  }

  havingId(id: string): this {
    this.dto.id = id;

    return this;
  }

  named(name: string): this {
    this.dto.name = name;

    return this;
  }

  build(): TGetUserTenants {
    return this.dto;
  }
}
