import { z } from 'nestjs-zod/z';

export const appleLoginSchema = z.object({
  idToken: z.string().min(1),
});

export const appleLoginResponseSchema = z.object({
  token: z.string(),
  email: z.string(),
});

export type TAppleLogin = z.infer<typeof appleLoginSchema>;
export type TAppleLoginResponse = z.infer<typeof appleLoginResponseSchema>;
