import { i18n } from '@lingui/core';

export const locales = {
  en: 'English',
  pl: 'Polski',
};

export const defaultLocale = 'en';

export async function loadCatalog(locale: string) {
  const { messages } = await import(`@lingui/loader!./locales/${locale}/messages.po`);
  // default zod messages
  const zodMessages = await import(`/locales/${locale}/zod.json`);
  // custom zod messages that we used in schema (contracts)
  const customZodMessages = await import(`/locales/${locale}/custom.zod.json`);
  // custom zod messages that we used in schema (contracts)
  const cognitoMessages = await import(`/locales/${locale}/cognito.json`);
  // translations of backend messages
  const backendMessages = await import(`/locales/${locale}/backend.json`);

  i18n.load(locale, messages);
  i18n.load(locale, zodMessages);
  i18n.load(locale, customZodMessages);
  i18n.load(locale, cognitoMessages);
  i18n.load(locale, backendMessages);

  i18n.activate(locale);
}
