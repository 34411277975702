import { z } from 'zod';

const formFieldValueSchema = z.object({
  id: z.string().optional(),
  value: z.string(),
});

export const changeFormFieldSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
  values: z.array(formFieldValueSchema),
  options: z.instanceof(Object).optional()
});


export type TChangeFormField = z.infer<typeof changeFormFieldSchema>;
