import { z } from 'nestjs-zod/z';
import { ContractType } from '../users';
import { TimeEntryType } from './types';

export const upsertTimeEntryActivityDataSchema = z.object({
  id: z.string().optional(),
  name: z.string().max(50),
  type: z.nativeEnum(TimeEntryType),
  reportedTimeRatio: z.number().max(10),
  billableReportedTimeRatio: z.number().max(10),
  contractTypes: z.array(z.nativeEnum(ContractType)),
  isArchived: z.boolean(),
});

export const upsertTimeEntryActivityDataFrontendSchema = z.object({
  id: z.string().optional(),
  name: z
    .string()
    .refine(value => value.trim().length > 0, { message: 'Work type name is required' })
    .refine(value => value.trim().length <= 50, { message: 'Name is max 50 chars' }),
  type: z.nativeEnum(TimeEntryType),
  reportedTimeRatio: z.number({ message: 'Reported time ratio must be provided' }).max(10, {
    message: 'Reported time ratio must be less than 1000%',
  }),
  billableReportedTimeRatio: z
    .number({ message: 'Billable reported time ratio must be provided' })
    .max(10, { message: 'Billable reported time ratio must be less than 1000%' }),
  contractTypes: z
    .array(z.nativeEnum(ContractType))
    .optional(),
  // .nonempty({ message: 'Contract type is required' }),
  isArchived: z.boolean(),
});

export type TUpsertTimeEntryActivityData = z.infer<typeof upsertTimeEntryActivityDataSchema>;
