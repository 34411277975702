export enum SlackManagmentAction {
  MANAGE = 'manage',
  LIST_ALL = 'list_all',
  READ = 'read',
}

export enum SlackManagmentSubject {
  SUBJECT = 'SlackManagment',
}

export type SlackManagmentClaims = [SlackManagmentAction, 'SlackManagment'];
