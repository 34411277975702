import { AutoGeneratedId } from '../id';
import { TGetTimeEntryData } from './get-time-entry-data.schema.type';
import { TimeEntryType } from './types';

export class GetTimeEntryDataDtoBuilder {
  private constructor(private dto: TGetTimeEntryData) {}

  static prefilled(): GetTimeEntryDataDtoBuilder {
    return new GetTimeEntryDataDtoBuilder({
      id: AutoGeneratedId(),
      date: '2024-02-05T00:00:00.000Z',
      reportedMinutes: 480,
      billableReportedMinutes: 480,
      type: TimeEntryType.WORK, // @TODO: H20-1294
      description: 'meetings',
      reportedAt: '2024-02-05T14:20:21.820Z',
      user: {
        cognitoId: AutoGeneratedId(),
        email: 'user@example.com',
      },
      descriptionOptionSet: null,
    });
  }

  havingId(id: string): this {
    this.dto.id = id;

    return this;
  }

  forDate(date: Date | string): this {
    this.dto.date = date;

    return this;
  }

  build(): TGetTimeEntryData {
    return this.dto;
  }
}
