import {
  IRuleDto,
  Paginated,
  TGetUserDataDTO,
  TGetUserPlan,
  TMe,
  TUpdateUser,
  TUpsertUserCalendarsDTO,
  TUpsertUserDataDTO,
  UserStatus,
} from '@hub/contracts';
import { AxiosRequestConfig } from 'axios';
import { IHttpClient } from '../types';

type PaginateQueryWithoutPath = {
  page?: number;
  limit?: number;
  sortBy?: [string, string][] | string[];
  searchBy?: string[] | string;
  search?: string;
  filter?: { [column: string]: string | string[] };
};

interface IUserApi {
  getUsers: (paginateQuery: PaginateQueryWithoutPath) => Promise<Paginated<TGetUserDataDTO>>;
  getCurrentUser: () => Promise<TMe>;
  getCurrentUserAbilities: () => Promise<IRuleDto[]>;
  updateUser: (userData: TUpsertUserDataDTO) => Promise<TUpsertUserDataDTO>;
  updateMe: (userId: string, userData: TUpdateUser) => Promise<TUpdateUser>;
  getUser: (userId: string) => Promise<TGetUserDataDTO>;
  getUserPlan: () => Promise<TGetUserPlan>;
  createUser: (data: TUpsertUserDataDTO) => Promise<TUpsertUserDataDTO>;
  exportNonCompeteToCsv: (params: {
    id: string;
    fileName: string;
    terminationDate: string;
  }) => Promise<unknown>;
}

export class UserApi implements IUserApi {
  baseUrl = '/api/v2/users';
  private client: IHttpClient;

  constructor(client: IHttpClient) {
    this.client = client;
  }

  getUser(userId: string): Promise<TGetUserDataDTO> {
    return this.client.get(`${this.baseUrl}/${userId}/`);
  }

  getCurrentUser(): Promise<TMe> {
    return this.client.get(`${this.baseUrl}/me/`);
  }

  getCurrentUserAbilities(): Promise<IRuleDto[]> {
    return this.client.get(`/api/me/abilities/`);
  }

  updateUser(userData: TUpsertUserDataDTO): Promise<TUpsertUserDataDTO> {
    const { id, ...data } = userData;
    return this.client.put(`${this.baseUrl}/${id}/`, { ...data });
  }

  updateMe(userId: string, userData: TUpdateUser): Promise<TUpdateUser> {
    return this.client.put(`${this.baseUrl}/me/`, { ...userData });
  }

  getUsers(
    paginateQuery?: PaginateQueryWithoutPath,
    status?: UserStatus.Active | UserStatus.Archived | undefined,
    cfg?: AxiosRequestConfig,
  ): Promise<Paginated<TGetUserDataDTO>> {
    return this.client.get(`${this.baseUrl}/`, {
      ...cfg,
      params: {
        searchBy: ['lastName', 'firstName', 'customExternalId'],
        sortBy: ['firstName:ASC', 'lastName:ASC', 'cognitoId:ASC'],
        limit: 1000,
        ['filter.status']: status,
        ...paginateQuery,
      },
    });
  }

  getUserPlan(): Promise<TGetUserPlan> {
    return this.client.get(`${this.baseUrl}/plan/me/`);
  }

  async createUser(data: TUpsertUserDataDTO): Promise<TUpsertUserDataDTO> {
    return await this.client.post(this.baseUrl, data);
  }

  async exportNonCompeteToCsv(params: {
    id: string;
    fileName: string;
    terminationDate: string;
  }): Promise<unknown> {
    return this.client
      .get(this.baseUrl + `/${params.id}/non-compete-list/`, {
        params: { ['termination-date']: params.terminationDate, format: 'csv' },
      })
      .then(response => {
        const href = URL.createObjectURL(new Blob([response as string], { type: 'text/csv' }));

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${params.fileName}.csv`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  attachCalendarsToTheUser(requestData: TUpsertUserCalendarsDTO & { id: string }): Promise<void> {
    const { id, ...data } = requestData;
    return this.client.post(`${this.baseUrl}/${id}/calendars`, data);
  }
}

export const userApiFactory = (httpClient: IHttpClient) => new UserApi(httpClient);
