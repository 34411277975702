import { z } from 'nestjs-zod/z';

const formFieldValueSchema = z.object({
  id: z.string(),
  value: z.string(),
});

const formFieldSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
  values: z.array(formFieldValueSchema),
});

const formSchema = z.object({
  id: z.string(),
  name: z.string(),
  fields: z.array(formFieldSchema),
});

export type TFormFieldValue = z.infer<typeof formFieldValueSchema>;
export type TFormField = z.infer<typeof formFieldSchema>;
export type TForm = z.infer<typeof formSchema>;