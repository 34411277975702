import {
  IRuleDto,
  Paginated,
  TGetUserDataDTO,
  TGetUserPlan,
  TMe,
  TUpdateUser,
  TUpsertUserDataDTO,
} from '@hub/contracts';
import { CognitoApi } from './CognitoApi/CognitoApi';

export enum CognitoErrorCode {
  NotAuthorizedException = 'NotAuthorizedException',
  UserNotFoundException = 'UserNotFoundException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  CodeMismatchException = 'CodeMismatchException',
  ExpiredCodeException = 'ExpiredCodeException',
  InvalidPasswordException = 'InvalidPasswordException',
  InvalidParameterException = 'InvalidParameterException',
  LimitExceededException = 'LimitExceededException',
  UserAlreadyAuthenticatedException = 'UserAlreadyAuthenticatedException'
}

export enum CognitoContext {
  ResetPassword = 'ResetPassword',
}


export enum CognitoAppErrorMessages {
  UserNotConfirmed = 'User account not confirmed. Check your mail',
  General = 'Something goes wrong',
  InvalidUserOrPassword = 'Invalid credentials',
  CodeMismatchException = 'Wrong verification code',
  ExpiredCodeException = 'Code expired. Resend new code',
  TooManyLoginAttempts = "Too many login attempts. Try again in 15 minutes."
}

export interface IUserNotConfirmedError extends CognitoError {
  email: string;
}

export class UserNotConfirmedError implements IUserNotConfirmedError {
  constructor(public name: string, public message: string, public email: string) { }
}

export interface CognitoError extends Error {
  message: string;
  name: string
}

export interface AuthProviderProps {
  cognitoApi: CognitoApi;
  ssrUser: TMe | null;
  currentUserAbilities: IRuleDto[] | null;
  authApi: IAuthApi;
  onSignOut: () => void;
}
export interface IAuthUser extends TMe {
  fullName?: string;
}

export type IAuthContext = {
  cognitoApi?: CognitoApi;
  currentUser: IAuthUser | null;
  currentUserAbilities: IRuleDto[] | null;
  setCurrentAuthenticatedUser: () => Promise<void>;
  signOut: () => void;
};

export interface IAuthEnvs {
  cognitoRegion: string;
  cognitoUserPoolId: string;
  cognitoUserPoolWebClientId: string;
  cognitoOAuthDomain: string;
  cognitoOAuthRedirectSignIn: string;
  cognitoOAuthRedirectSignOut: string;
}

type PaginateQueryWithoutPath = {
  page?: number;
  limit?: number;
  sortBy?: [string, string][];
  searchBy?: string[];
  search?: string;
  filter?: { [column: string]: string | string[] };
};

export interface IAuthApi {
  getUsers: (paginateQuery: PaginateQueryWithoutPath) => Promise<Paginated<TGetUserDataDTO>>;
  getCurrentUser: () => Promise<TMe>;
  getCurrentUserAbilities: () => Promise<IRuleDto[]>;
  updateUser: (userData: TUpsertUserDataDTO) => Promise<TUpsertUserDataDTO>;
  updateMe: (userId: string, userData: TUpdateUser) => Promise<TUpdateUser>;
  getUser: (userId: string) => Promise<TGetUserDataDTO>;
  getUserPlan: () => Promise<TGetUserPlan>;
  createUser: (data: TUpsertUserDataDTO) => Promise<TUpsertUserDataDTO>;
}
