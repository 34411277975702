import { z } from 'nestjs-zod/z';
import { ProjectCategory, ProjectStatus } from './types';

export const descriptionOptionUpsertSetSchema = z.array(
  z.object({
    id: z.string().optional(),
    option: z.string(),
  }),
);

export const upsertProjectDataSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  description: z.string().optional(),
  clientId: z.string().optional(),
  category: z.nativeEnum(ProjectCategory).optional(),
  status: z.nativeEnum(ProjectStatus).optional(),
  accountManagerId: z.string().nullable().optional(),
  projectManagerId: z.string().nullable().optional(),
  pipeDriveDealId: z.string().optional(),
  createdAt: z.union([z.date(), z.string()]).optional(),
  updatedAt: z.union([z.date(), z.string()]).optional(),
  descriptionOptionSet: descriptionOptionUpsertSetSchema.optional(),
});

export type TUpsertProjectData = z.infer<typeof upsertProjectDataSchema>;
