import { z } from 'nestjs-zod/z';

export const getClientDataSchema = z.object({
  id: z.string(),
  name: z.string(),
  invoicingData: z.string(),
  contactPerson: z.string(),
  contactInformation: z.string(),
  accountManager: z
    .object({
      id: z.string(),
      firstName: z.string().nullable(),
      lastName: z.string().nullable(),
    })
    .optional(),
  isArchived: z.boolean(),
  createdAt: z.union([z.date(), z.string()]).optional(),
  updatedAt: z.union([z.date(), z.string()]).optional(),
});

export type TGetClientData = z.infer<typeof getClientDataSchema>;
