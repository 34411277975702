import {
  CognitoAppErrorMessages,
  CognitoContext,
  CognitoError,
  CognitoErrorCode
} from './Auth.interface';

export function isCognitoError(error: unknown): error is CognitoError {
  return (error instanceof Error) && (error as any).name && (error as any).message
}

export interface ICognitoErrorTextOptions {
  errorContext?: CognitoContext;
  generalErrorText?: string;
}

export function getCognitoErrorText(
  error: unknown,
  options?: ICognitoErrorTextOptions
): string {
  if (!isCognitoError(error)) {
    return CognitoAppErrorMessages.General
  }

  const generalErrorText = options?.generalErrorText || error.message || CognitoAppErrorMessages.General;


  switch (error.name) {
    case CognitoErrorCode.UserNotConfirmedException:
      return CognitoAppErrorMessages.UserNotConfirmed;
    case CognitoErrorCode.NotAuthorizedException:
      if (error.message.includes('attempts exceeded')) {
        return CognitoAppErrorMessages.TooManyLoginAttempts;
      }
      return CognitoAppErrorMessages.InvalidUserOrPassword;
    case CognitoErrorCode.UserNotFoundException:
      return CognitoAppErrorMessages.InvalidUserOrPassword;
    case CognitoErrorCode.CodeMismatchException:
      return CognitoAppErrorMessages.CodeMismatchException;
    case CognitoErrorCode.ExpiredCodeException:
      return CognitoAppErrorMessages.ExpiredCodeException;
    case CognitoErrorCode.InvalidParameterException:
      if (options?.errorContext === CognitoContext.ResetPassword) {
        return CognitoAppErrorMessages.UserNotConfirmed;
      }
      return generalErrorText;
    default:
      return generalErrorText;
  }
}
