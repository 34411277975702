import { z } from 'nestjs-zod/z';

export const tenantSettingsSchema = z.object({
  id: z.string(),
  name: z.string(),
  logoUrl: z.string().nullable(),
  canManuallyReportTimeOffs: z.boolean(),
});

export type TTenantSettings = z.infer<typeof tenantSettingsSchema>;
