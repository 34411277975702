import { z } from 'nestjs-zod/z';
import { ContractType, UserRole, UserStatus } from './types';

export enum SeniorityLevel {
  JUNIOR = 'JUNIOR',
  MID = 'MID',
  SENIOR = 'SENIOR',
  TEAM_LEAD = 'TEAM_LEAD',
}

export const upsertUserDataSchema = z.object({
  id: z.string().optional(),
  email: z.string().email(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  role: z.nativeEnum(UserRole),
  contractType: z.nativeEnum(ContractType),
  contractStartedOn: z.dateString().nullable(),
  contractEndedOn: z.dateString().nullable(),
  status: z.nativeEnum(UserStatus),
  department: z.string().min(1),
  workingTime: z.preprocess(
    (x: any) => (x == '' ? undefined : Number(x)),
    z.number().nonnegative(),
  ),
  assignedProjects: z.array(z.string().uuid()).optional(),
  seniorityLevel: z.string().nullable().optional(),
  team: z.string().nullable().optional(),
  customExternalId: z.string().optional().nullable(),
});

export type TUpsertUserDataDTO = z.infer<typeof upsertUserDataSchema>;
