import { z } from 'nestjs-zod/z';
import { EPaymentType } from './enums';

export const productSchema = z.object({
    id: z.string(),
    name: z.string(),
    price: z.object({
        id: z.string(),
        amount: z.number().nullable(),
        currency: z.string(),
        type: z.nativeEnum(EPaymentType),
    })
})

export type TProductListItem = z.infer<typeof productSchema>;
