import { z } from 'nestjs-zod/z';

export const projectStatsSchema = z.object({
  userId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  reportedHours: z.number().positive(),
  billableReportedHours: z.number().positive(),
});

export const projectStatsSchemaSummary = z.object({
  users: z.array(projectStatsSchema),
  totalHours: z.number().positive(),
  billableTotalHours: z.number().positive(),
});

export type TProjectStats = z.infer<typeof projectStatsSchema>;
export type TProjectStatsSummary = z.infer<typeof projectStatsSchemaSummary>;
