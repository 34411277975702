import { TPeriodListItem } from './list-periods';
import { AutoGeneratedId } from '../id';
import { TEmployeeWorkingTimeSummary } from './employee-working-time-summary';
import { BillingPeriodStatus } from './types';

export class PeriodListItemBuilder {
  private constructor(private dto: TPeriodListItem) {}

  static prefilled(): PeriodListItemBuilder {
    return new PeriodListItemBuilder({
      id: AutoGeneratedId(),
      name: 'Custom approval period',
      period: {
        from: '2024-02-01',
        to: '2024-02-07',
      },
      status: BillingPeriodStatus.NotStarted,
      numberOfApprovedUsers: 0,
    });
  }

  named(name: string): this {
    this.dto.name = name;

    return this;
  }

  withinPeriod(from: string, to: string): this {
    this.dto.period.from = from;
    this.dto.period.to = to;

    return this;
  }

  notStarted(): this {
    this.dto.status = BillingPeriodStatus.NotStarted;

    return this;
  }

  inProgress(): this {
    this.dto.status = BillingPeriodStatus.InProgress;

    return this;
  }

  approved(): this {
    this.dto.status = BillingPeriodStatus.Approved;

    return this;
  }

  build(): TPeriodListItem {
    return this.dto;
  }
}

export class EmployeeWorkingTimeSummaryDtoBuilder {
  private constructor(private dto: TEmployeeWorkingTimeSummary) {}

  static prefilled(): EmployeeWorkingTimeSummaryDtoBuilder {
    return new EmployeeWorkingTimeSummaryDtoBuilder({
      userId: AutoGeneratedId(),
      firstName: 'John',
      lastName: 'Smith',
      contract: 'B2B',
      userReportStatistics: [],
      comment: '',
      approved: false,
      hasWarning: false,
    });
  }

  named(firstName: string, lastName: string): this {
    this.dto.firstName = firstName;
    this.dto.lastName = lastName;

    return this;
  }

  onB2B(): this {
    this.dto.contract = 'B2B';

    return this;
  }

  onUoP(): this {
    this.dto.contract = 'UoP';

    return this;
  }

  external(): this {
    this.dto.contract = 'External';

    return this;
  }

  clearStats(): this {
    this.dto.userReportStatistics = [];

    return this;
  }

  havingStat(name: string, value: number): this {
    this.dto.userReportStatistics.push({
      name,
      value,
    });

    return this;
  }

  havingComment(comment: string): this {
    this.dto.comment = comment;

    return this;
  }

  notApproved(): this {
    this.dto.approved = false;

    return this;
  }

  approved(): this {
    this.dto.approved = true;

    return this;
  }

  withoutIssue(): this {
    this.dto.hasWarning = false;

    return this;
  }

  havingIssue(): this {
    this.dto.hasWarning = true;

    return this;
  }

  havingWarning(): this {
    this.dto.hasWarning = true;

    return this;
  }

  build(): TEmployeeWorkingTimeSummary {
    return this.dto;
  }
}
