import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

export type TAxiosErrorInterceptor = (error: AxiosError) => AxiosError;

export interface IHttpClientOptions {
  url: string;
  accessToken?: string;
  tenantId?: string;
  useRequestConfig?: (
    requestConfig: InternalAxiosRequestConfig,
  ) => Promise<InternalAxiosRequestConfig>;
  onErrorInterceptor?: TAxiosErrorInterceptor;
}

export interface IHttpClient {
  get<TReturnType>(
    url: string,
    queryDto?: { params?: Record<string, unknown> },
    config?: AxiosRequestConfig,
  ): Promise<TReturnType>;
  post<TRequestDataType, TReturnType>(
    url: string,
    requestData: TRequestDataType,
    config?: AxiosRequestConfig,
  ): Promise<TReturnType>;
  put<TRequestDataType, TReturnType>(
    url: string,
    requestData: TRequestDataType,
    config?: AxiosRequestConfig,
  ): Promise<TReturnType>;
  patch<TRequestDataType, TReturnType>(
    url: string,
    requestData: TRequestDataType,
  ): Promise<TReturnType>;
  delete<TRequestDataType, TReturnType>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<TReturnType>;
}

export class HttpClient implements IHttpClient {
  private static instance: HttpClient;
  private readonly client: AxiosInstance;
  private readonly options: IHttpClientOptions;

  constructor(options: IHttpClientOptions) {
    this.options = options;
    this.client = axios.create({
      baseURL: this.options.url,
      headers: this.getHeaders(),
    });

    this.client.interceptors.request.use((config: InternalAxiosRequestConfig) =>
      this.options.useRequestConfig ? this.options.useRequestConfig(config) : config,
    );

    this.client.interceptors.response.use(
      (response: AxiosResponse) => response,
      error => {
        return this.options.onErrorInterceptor
          ? Promise.reject(this.options.onErrorInterceptor(error))
          : Promise.reject(error);
      },
    );
  }

  public static getInstance(options: IHttpClientOptions): HttpClient {
    if (!HttpClient.instance) {
      HttpClient.instance = new HttpClient(options);
    }
    return HttpClient.instance;
  }

  async get<TReturnType>(
    url: string,
    { params }: { params?: Record<string, unknown> } = {},
  ): Promise<TReturnType> {
    const { data } = await this.client.get<TReturnType>(url, { params });
    return data;
  }

  async post<TRequestDataType, TReturnType = void>(
    url: string,
    requestData: TRequestDataType,
    config?: AxiosRequestConfig,
  ): Promise<TReturnType> {
    const { data } = await this.client.post<TRequestDataType, AxiosResponse<TReturnType>>(
      url,
      requestData,
      config,
    );
    return data;
  }

  async put<TRequestDataType, TReturnType = void>(
    url: string,
    requestData: TRequestDataType,
    config?: AxiosRequestConfig,
  ): Promise<TReturnType> {
    const { data } = await this.client.put<TRequestDataType, AxiosRequestConfig>(
      url,
      requestData,
      config,
    );

    return data;
  }

  async patch<TRequestDataType, TReturnType = void>(
    url: string,
    requestData: TRequestDataType,
  ): Promise<TReturnType> {
    const { data } = await this.client.patch<TRequestDataType, AxiosResponse<TReturnType>>(
      url,
      requestData,
    );
    return data;
  }

  async delete<TRequestDataType, TReturnType = void>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<TReturnType> {
    const { data } = await this.client.delete<TRequestDataType, AxiosResponse<TReturnType>>(
      url,
      config,
    );

    return data;
  }

  getToken() {
    return this.options.accessToken;
  }

  getTenantId() {
    return this.options.tenantId;
  }

  private getHeaders(): Record<string, string> {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // 'X-Request-Id': uuid4(),
      ...(this.options.accessToken ? { Authorization: `Bearer ${this.options.accessToken}` } : {}),
      ...(this.options.tenantId ? { 'x-tenant-id': `${this.options.tenantId}` } : {}),
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}
