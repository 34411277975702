import { z } from 'nestjs-zod/z';
import { allowedColumnNames, exportFormats } from './common';

export const exportTimeEntriesQuerySchema = z.object({
  from: z
    .string()
    .datetime()
    .transform(dateTimeIsoString => new Date(dateTimeIsoString)),
  to: z
    .string()
    .datetime()
    .transform(dateTimeIsoString => new Date(dateTimeIsoString)),
  format: exportFormats,
  columns: z.array(allowedColumnNames).min(1),
  columnsNames: z.string().transform(v => JSON.parse(v)).pipe(z.record(allowedColumnNames, z.string())),
});

export type TAllowedColumnsNames = z.infer<typeof allowedColumnNames>;

export type TExportTimeEntriesInputQuery = z.input<typeof exportTimeEntriesQuerySchema>;
export type TExportTimeEntriesQuery = z.infer<typeof exportTimeEntriesQuerySchema>;
