export enum ProjectAction {
  MANAGE = 'manage',
  LIST_ALL = 'list_all',
  LIST = 'list',
}

export enum ProjectSubject {
  SUBJECT = 'Project',
}

export type ProjectClaims = [ProjectAction, 'Project'];
