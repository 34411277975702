export enum ExternaLinkAction {
  MANAGE = 'manage',
  LIST_ALL = 'list_all',
  READ = 'read',
}

export enum ExternalLinkSubject {
  SUBJECT = 'ExternalLink',
}

export type ExternalLinkClaims = [ExternaLinkAction, 'ExternalLink'];
