import { z } from 'nestjs-zod/z';

export enum TimeUnit {
  min = "min",
  hours = "hours"
}

export const DefaultPerUnit: Record<TimeUnit, number> = {
  [TimeUnit.min]: 1,
  [TimeUnit.hours]: 0.25
}

export const timeReportingIncrementalValuesForHours = [15, 30, 60];
export const timeReportingIncrementalValuesForMinutes = [1, 5, 10, 15, 30]

export const tenantReportedTimeSettings = z.object({
  timeUnit: z.nativeEnum(TimeUnit),
  minimalValueInMinutes: z.number(),
}).refine(input => {
  if (input.timeUnit === TimeUnit.hours) {
    return timeReportingIncrementalValuesForHours.includes(input.minimalValueInMinutes)
  }
  return timeReportingIncrementalValuesForMinutes.includes(input.minimalValueInMinutes)
}, { message: 'Invalid incremental value for selected unit', path: ['minimalValueInMinutes'] });

export type TTenantReportedTimeSettings = z.infer<typeof tenantReportedTimeSettings>;

