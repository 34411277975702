import { z } from 'nestjs-zod/z';
import { TimeEntryType } from './types';

export const getTimeOffEntryDataSchema = z.object({
  id: z.string(),
  date: z.union([z.date(), z.string()]),
  reportedMinutes: z.number().nonnegative().multipleOf(0.25),
  type: z.nativeEnum(TimeEntryType),
  calamariSyncId: z.string().min(1),
  user: z.object({
    cognitoId: z.string(),
    email: z.string(),
  }),
  createdAt: z.union([z.date(), z.string()]).optional(),
  updatedAt: z.union([z.date(), z.string()]).optional(),
});

export type TGetTimeOffEntryData = z.infer<typeof getTimeOffEntryDataSchema>;
