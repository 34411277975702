import { z } from 'zod';

export const calendarListItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  numberOfUsers: z.number(),
  numberOfDays: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type TCalendarListItem = z.infer<typeof calendarListItemSchema>;
