import { z } from 'nestjs-zod/z';
import { passwordValidator } from '../validators/password';

export const captchaSchema = z.object({
  gReCaptchaToken: z.string(),
});

export const signUpSchema = z
  .object({
    tenantName: z.string().min(1, 'Required'),
    firstName: z.string().min(1, 'Required'),
    lastName: z.string().min(1, 'Required'),
    email: z.string().email('Invalid email address'),
    password: passwordValidator,
    repeatPassword: passwordValidator,
    acceptTermsAndConditions: z.literal(true)
  })
  .refine(data => data.password === data.repeatPassword, {
    message: 'Passwords must match',
    path: ['repeatPassword'],
  });

export const signUpFirstStepSchema = z
  .object({
    tenantName: z.string().min(1, 'Required'),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email('Invalid email address'),
    password: passwordValidator,
    repeatPassword: passwordValidator,
  })
  .refine(data => data.password === data.repeatPassword, {
    message: 'Passwords must match',
    path: ['repeatPassword'],
  });

export const signUpWithCaptchaSchema = signUpSchema.and(captchaSchema);

export type TSignUp = z.infer<typeof signUpSchema>;
export type TSignUpWithCaptcha = z.infer<typeof signUpWithCaptchaSchema>;
