import { z } from 'nestjs-zod/z';
import { TimeEntryType } from './types';
import { ProjectCategory } from '../project';

export const getTimeEntryDataSchema = z.object({
  id: z.string(),
  date: z.union([z.date(), z.string()]),
  reportedMinutes: z.number().nonnegative().gt(0),
  billableReportedMinutes: z.number(),
  type: z.nativeEnum(TimeEntryType),
  description: z.string(),
  reportedAt: z.union([z.date(), z.string()]).optional(),
  user: z.object({
    cognitoId: z.string(),
    email: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
  }),
  project: z
    .object({
      id: z.string(),
      name: z.string(),
      category: z.nativeEnum(ProjectCategory),
    })
    .optional(),
  calamariSyncId: z.string().optional(),
  approvedAt: z.dateString().optional(),
  createdAt: z.union([z.date(), z.string()]).optional(),
  updatedAt: z.union([z.date(), z.string()]).optional(),
  descriptionOptionSet: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
});

export type TGetTimeEntryData = z.infer<typeof getTimeEntryDataSchema>;
