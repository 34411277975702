export enum EPaymentStatus {
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  COMPLETED = 'COMPLETED',
}

export enum EPaymentType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ONE_TIME_PAYMENT = 'ONE_TIME_PAYMENT',
}
