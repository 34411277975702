import { Project } from '@prisma/client';
import { TDescriptionOptionSet } from './get-project-data.schema.type';

export type ProjectId = string & { readonly __type: unique symbol };

export type ProjectWithRelationsData = Project & {
  client: { id: string; name: string } | null;
  accountManager: { firstName: string | null; lastName: string | null; cognitoId: string } | null;
  projectManager: { firstName: string | null; lastName: string | null; cognitoId: string } | null;
  descriptionOptionSet: TDescriptionOptionSet;
};

export enum ProjectCategory {
  Commercial = 'COMMERCIAL',
  Internal = 'INTERNAL',
  Other = 'OTHER',
}

export enum AdditionalProjectCategory {
  Favorites = 'FAVORITES',
}

export type ExtendedProjectCategory = ProjectCategory | AdditionalProjectCategory;

export enum ProjectStatus {
  Active = 'ACTIVE',
  Retainer = 'RETAINER',
  Archived = 'ARCHIVED',
}
