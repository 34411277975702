export enum TimeEntryAction {
  MANAGE = 'manage',
  LIST_PARTIAL = 'list_partial',
  LIST_ALL = 'list_all',
  READ = 'read',
  DELETE = 'delete',
}

export enum TimeEntrySubject {
  SUBJECT = 'TimeEntry',
}

export interface ITimeEntrySubject {
  userId: string;
}

export type TimeEntryClaims = [TimeEntryAction, ITimeEntrySubject | 'TimeEntry'];
