export enum UserAction {
  MANAGE = 'manage',
  READ = 'read',
  LIST_ALL = 'listAll',
}

export enum UserSubject {
  SUBJECT = 'User',
}

export interface IUserSubject {
  id: string;
}

export type UserClaims = [UserAction, IUserSubject | 'User'];
