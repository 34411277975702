import { fetchAuthSession as fetchAuthSessionServer } from 'aws-amplify/auth/server';
import { deleteCookie, getCookie } from 'cookies-next';
import { IncomingMessage, ServerResponse } from 'http';
import { JwtPayload } from 'jsonwebtoken';
import { IHttpClient, IHttpClientOptions } from './cognito-auth.types';

export interface IOptions {
  accessToken?: string;
  tenantId?: string;
}

export class ServerHttpClient {
  // public static jwtTokenCookieName = 'cognitoJwtToken';

  public static isTokenExpired(payload: JwtPayload) {
    return payload.exp && payload.exp * 1000 < Date.now();
  }

  public static deleteJWTCookie(args: {
    req: IncomingMessage;
    res: ServerResponse<IncomingMessage>;
  }) {
    deleteCookie('x-tenant-id', {
      req: args.req,
      res: args.res,
    });
  }

  static async getOptionsFromRequest(args: {
    req: IncomingMessage;
    res: ServerResponse<IncomingMessage>;
  }, runWithAmplifyServerContext: any): Promise<IHttpClientOptions> {


    try {
      const options: { req?: any } = { req: args.req };
      const authSession = await runWithAmplifyServerContext({
        nextServerContext: { request: args.req, response: args.res },
        operation: async (contextSpec: any) => fetchAuthSessionServer(contextSpec)
      });

      const tenantId = getCookie('x-tenant-id', { req: args.req, res: args.res }) as string | undefined;

      if (authSession.tokens?.idToken && !this.isTokenExpired(authSession.tokens?.idToken.toString())) {
        delete options.req;
        return {
          accessToken: authSession.tokens?.idToken.toString(),
          tenantId,
        };
      }

      const token = authSession.tokens?.idToken?.toString()
      delete options.req;

      return {
        accessToken: token,
        tenantId,
      };
    } catch {
      return {};
    }
  }

  static async fromRequest(
    args: {
      req: IncomingMessage;
      res: ServerResponse<IncomingMessage>;
    },
    runWithAmplifyServerContext: any,
    httpClientFactory: (options: IHttpClientOptions) => IHttpClient,
  ) {
    try {
      const authSession = await runWithAmplifyServerContext({
        nextServerContext: { request: args.req, response: args.res },
        operation: async (contextSpec: any) => fetchAuthSessionServer(contextSpec)
      });

      const token = authSession.tokens?.idToken

      const tenantId = getCookie('x-tenant-id', { req: args.req, res: args.res }) as
        | string
        | undefined;

      return httpClientFactory({
        accessToken: token?.toString(),
        tenantId,
      });
    } catch (error: any) {
      console.log(error)
      return httpClientFactory({});
    }
  }
}
