import { z } from 'nestjs-zod/z';

const recipientSchema = z.object({
  email: z.string().optional().nullable(),
  external_id: z.string().optional().nullable(),
  matches: z.string().optional(),
});

export const magicbellNotificationSchema = z.object({
  title: z.string(),
  content: z.string().optional().nullable(),
  topic: z.string().optional().nullable(),
  recipients: z.array(recipientSchema).min(1),
});

export type TMagicBellNotification = z.infer<typeof magicbellNotificationSchema>;
