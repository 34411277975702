import { z } from 'nestjs-zod/z';

export const googleLoginSchema = z.object({
  credential: z.string().min(1),
});

export const googleLoginResponseSchema = z.object({
  token: z.string(),
  email: z.string(),
});

export type TGoogleLogin = z.infer<typeof googleLoginSchema>;
export type TGoogleLoginResponse = z.infer<typeof googleLoginResponseSchema>;
