import { z } from 'nestjs-zod/z';
import { ProjectCategory, ProjectStatus } from './types';

export const descriptionOptionSetSchema = z.array(
  z.object({
    id: z.string(),
    option: z.string(),
  }),
);

export const getProjectDataSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  client: z
    .object({
      id: z.string(),
      name: z.string().nullable(),
    })
    .optional(),
  category: z.nativeEnum(ProjectCategory),
  status: z.nativeEnum(ProjectStatus),
  accountManager: z
    .object({
      id: z.string(),
      firstName: z.string().nullable(),
      lastName: z.string().nullable(),
    })
    .optional(),
  projectManager: z
    .object({
      id: z.string(),
      firstName: z.string().nullable(),
      lastName: z.string().nullable(),
    })
    .optional(),
  pipeDriveDealId: z.string(),
  createdAt: z.union([z.date(), z.string()]).optional(),
  updatedAt: z.union([z.date(), z.string()]).optional(),
  descriptionOptionSet: descriptionOptionSetSchema.optional().nullable(),
});

export const projectDataWithDescriptionOptionSetSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  category: z.nativeEnum(ProjectCategory),
  descriptionOptionSet: descriptionOptionSetSchema.optional().nullable(),
  status: z.nativeEnum(ProjectStatus),
  pipeDriveDealId: z.string(),
  createdAt: z.union([z.date(), z.string()]).optional(),
  updatedAt: z.union([z.date(), z.string()]).optional(),
});

export type TProjectDataWithDescriptionOptionSet = z.infer<
  typeof projectDataWithDescriptionOptionSetSchema
>;
export type TGetProjectData = z.infer<typeof getProjectDataSchema>;
export type TDescriptionOptionSet = z.infer<typeof descriptionOptionSetSchema>;
