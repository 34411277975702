import { z } from 'nestjs-zod/z';

export const slackTaskSchema = z.object({
  id: z.string(),
  name: z.string(),
  channel: z.string(),
  text: z.string(),
  cronExpression: z.string(),
});

export const updateSlackTaskSchema = z.object({
  name: z.string().min(1).optional(),
  channel: z.string().min(1).optional(),
  text: z.string().min(1).optional(),
  cronExpression: z.string().min(1).optional(),
});

export const createSlackTaskSchema = z.object({
  name: z.string().min(1),
  channel: z.string().min(1),
  text: z.string().min(1),
  cronExpression: z.string().min(1),
});

export const slackSendMessageSchema = z.object({
  channel: z.string().min(1),
  message: z.string().min(1),
});
export type TSlackSendMessage = z.infer<typeof slackSendMessageSchema>;

export type TCreateSlackTask = z.infer<typeof createSlackTaskSchema>;

export const getSlackTasksSchema = z.array(slackTaskSchema);
export type TUpdateSlackTask = z.infer<typeof updateSlackTaskSchema>;

export type TSlackTask = z.infer<typeof slackTaskSchema>;
export type TGetSlackTasks = z.infer<typeof getSlackTasksSchema>;
