import { z } from 'nestjs-zod/z';
import { TimeEntryType } from './types';

export const upsertTimeOffEntryDataSchema = z.object({
  id: z.string().optional(),
  date: z.union([z.date(), z.string()]),
  reportedMinutes: z.number().multipleOf(0.25),
  description: z.string().optional(),
  reportedAt: z.union([z.date(), z.string()]).optional(),
  type: z.enum([ // @TODO: H20-1294
    TimeEntryType.OFF_PAID,
    TimeEntryType.OFF_UNPAID,
    TimeEntryType.OFF_CONFERENCE,
    TimeEntryType.OFF_SICK,
  ]),
  userEmail: z.string().min(1),
  calamariSyncId: z.string().min(1),
  createdAt: z.union([z.date(), z.string()]).optional(),
  updatedAt: z.union([z.date(), z.string()]).optional(),
});

export type TUpsertTimeOffEntryData = z.infer<typeof upsertTimeOffEntryDataSchema>;
