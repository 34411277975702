import { z } from 'nestjs-zod/z';
import { IsoDateStringToDateString, refineDateString } from '../utils';
import { TimeEntryType } from './types';

export const reportTimeOffSchema = z.object({
  type: z.nativeEnum(TimeEntryType),
  date: z
    .string()
    .transform(IsoDateStringToDateString)
    .refine(value => refineDateString(value)),
  reportedMinutes: z.number(),
  description: z.string(),
});

export const updateTimeOffSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(TimeEntryType),
  date: z
    .string()
    .transform(IsoDateStringToDateString)
    .refine(value => refineDateString(value)),
  reportedMinutes: z.number(),
  description: z.string(),
});


export const updateTimeOffSchemaFrontend = reportTimeOffSchema
  .extend({
    reportedMinutes: z.string(),
  });

export const reportTimeOffSchemaFrontend = reportTimeOffSchema.extend({
  reportedMinutes: z.string(),
})

export type TReportTimeOffSchema = z.infer<typeof reportTimeOffSchema>;

export type TUpdateTimeOffSchema = z.infer<typeof updateTimeOffSchema>;

export type TUpdateTimeOffFrontendSchema = z.infer<typeof updateTimeOffSchemaFrontend>;
