import { z } from 'nestjs-zod/z';
import { allowedColumnNames, exportFormats } from './common';

export const listTimeEntriesExportTemplates = z.array(
  z.object({
    id: z.string().uuid(),
    name: z.string().min(1),
    format: exportFormats,
    columns: z.array(allowedColumnNames).min(1),
    columnsNames: z.record(allowedColumnNames, z.string()).optional(),
  }),
);

export type TListTimeEntriesExportTemplates = z.infer<typeof listTimeEntriesExportTemplates>;

export const saveTimeEntriesExportTemplate = z.object({
  name: z.string(),
  format: exportFormats,
  columns: z.array(allowedColumnNames).min(1),
  columnsNames: z.record(allowedColumnNames, z.string()),
});

export type TSaveTimeEntriesExportTemplate = z.infer<typeof saveTimeEntriesExportTemplate>;

export const timeEntriesExportTemplate = z.object({
  id: z.string().uuid(),
  name: z.string().min(1),
  template: z.object({
    format: exportFormats,
    columns: z.array(allowedColumnNames).min(1),
    columnsNames: z.record(allowedColumnNames, z.string()).optional(),
  }),
});

export type TTimeEntriesExportTemplate = z.infer<typeof timeEntriesExportTemplate>;
