export enum PersonalTimeReportAction {
  READ = 'read',
  MANAGE = 'manage',
}

export enum PersonalTimeReportSubject {
  SUBJECT = 'PersonalTimeReport',
}

export type PersonalTimeReportClaims = [PersonalTimeReportAction, 'PersonalTimeReport'];
