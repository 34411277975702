import { z } from 'nestjs-zod/z';

export enum ETypeOfWorkKey {
  INTERNAL = 'INTERNAL',
  COMMERCIAL = 'COMMERCIAL',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  OTHER = 'OTHER',
  TIME_OFF = 'TIME-OFF',
}

export const typeOfWorkSegment = z.object({
  key: z.nativeEnum(ETypeOfWorkKey),
  value: z.number().positive(),
  unit: z.string().min(1),
  percent: z.number().positive(),
});

export type TTypeOfWorkSegment = z.infer<typeof typeOfWorkSegment>;
