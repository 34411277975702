import { z } from 'zod';

export const createCalendarDaySchema = z.object({
  name: z.string().trim().min(1, 'Required'),
  date: z.string().refine(
    dateString => {
      const date = new Date(dateString);

      if (isNaN(date.getTime())) return false;

      return true;
    },
    {
      message: 'Invalid date format',
    },
  ),
});

export type TCreateCalendarDay = z.infer<typeof createCalendarDaySchema>;
