import { AutoGeneratedId } from '../id';
import { TProjectListItem } from './project-list-item.schema.type';
import { ProjectCategory, ProjectStatus } from './types';

export class ProjectListItemDtoBuilder {
  private constructor(private dto: TProjectListItem) {}

  static prefilled(): ProjectListItemDtoBuilder {
    return new ProjectListItemDtoBuilder({
      id: AutoGeneratedId(),
      name: 'HUB 2.0',
      category: ProjectCategory.Internal,
      status: ProjectStatus.Active,
      favorite: false,
    });
  }

  havingId(id: string): this {
    this.dto.id = id;

    return this;
  }

  named(name: string): this {
    this.dto.name = name;

    return this;
  }

  internal(): this {
    this.dto.category = ProjectCategory.Internal;

    return this;
  }

  active(): this {
    this.dto.status = ProjectStatus.Active;

    return this;
  }

  retainer(): this {
    this.dto.status = ProjectStatus.Retainer;

    return this;
  }

  archived(): this {
    this.dto.status = ProjectStatus.Archived;

    return this;
  }

  addSubProject(id: string, name: string): this {
    if (!this.dto.descriptionOptionSet) this.dto.descriptionOptionSet = [];

    this.dto.descriptionOptionSet.push({ id, option: name });

    return this;
  }

  build(): TProjectListItem {
    return this.dto;
  }
}
