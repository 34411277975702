import { z } from 'zod';

export const importCountryHolidaysPayloadSchema = z
  .object({
    calendarId: z.string(),
    countryIsoCode: z.string(),
    from: z.number().min(0), // timestamp
    to: z.number().min(0), // timestamp
  })
  .refine(({ from, to }) => from <= to, 'Invalid date range');

export type TImportCountryHolidaysPayloadDto = z.infer<typeof importCountryHolidaysPayloadSchema>;
