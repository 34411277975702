import { AutoGeneratedId } from '../id';
import { TMe } from './me.schema.type';

export class MeDtoBuilder {
  private constructor(private dto: TMe) {}

  static prefilled(): MeDtoBuilder {
    return new MeDtoBuilder({
      cognitoId: AutoGeneratedId(),
      email: 'jan.kowalski@elpassion.pl',
      firstName: 'Jan',
      lastName: 'Kowalski',
      avatarKey: null,
      avatarUrl: '',
      description: '',
      isSubscriptionInactive: false,
      isTrialActive: true,
    });
  }

  build(): TMe {
    return this.dto;
  }
}
