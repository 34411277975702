import { z } from 'nestjs-zod/z';
import { ContractType } from './types';

export const userContractSchema = z.object({
  contractType: z.nativeEnum(ContractType),
  contractStartedOn: z.dateString().nullable(),
  contractEndedOn: z.dateString().nullable(),
  department: z.string(),
  workingTime: z.number(),
});

export type TUserContract = z.infer<typeof userContractSchema>;
