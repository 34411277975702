import { z } from 'nestjs-zod/z';

export const updateSubscriptionSchema = z.object({
    quantity: z.number(),
    productId: z.string()
});

export const subscriptionUpdatedSchema = z.object({
    invoiceUrl: z.string().optional(),
    url: z.string().optional()
})

export type TUpdateSubscription = z.infer<typeof updateSubscriptionSchema>;
export type TSubscriptionUpdated = z.infer<typeof subscriptionUpdatedSchema>;
