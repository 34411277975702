import { z } from 'nestjs-zod/z';

export const eventSchema = z.object({
  id: z.number(),
  type: z.string(),
  details: z.object({
    email: z.string(),
  }),
  createdAt: z.date(),
});

export type TEvent = z.infer<typeof eventSchema>;
