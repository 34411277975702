import { z } from 'nestjs-zod/z';

export const meSchema = z.object({
  cognitoId: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  avatarKey: z.string().nullable(),
  avatarUrl: z.string(),
  description: z.string(),
  isTrialActive: z.boolean(),
  isSubscriptionInactive: z.boolean(),
  trialEndDate: z.number().optional(),
  lastSubscriptionEndDate: z.number().optional(),
});

export type TMe = z.infer<typeof meSchema>;
