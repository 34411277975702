import { z } from 'nestjs-zod/z';
import { MAX_REPORTED_MINUTES } from './max-reported-minutes';

const validateGroupRequired = (groupRequired: boolean, descriptionOptionSetId: string | null) => {
  if (groupRequired) {
    return descriptionOptionSetId;
  }
  return true;
}

const validateDescription = (groupRequired: boolean, description: string) => {
  if (!groupRequired) {
    return description;
  }
  return true;
}

export const upsertTimeWorkEntryDataSchema = z.object({
  id: z.string().optional(),
  date: z.union([z.date(), z.string()]),
  reportedMinutes: z.preprocess((v) => parseInt(v as string), z.number().nonnegative().multipleOf(0.25).max(MAX_REPORTED_MINUTES)),
  userId: z.string().uuid().optional(),
  projectId: z.string().uuid('Required'),
  createdAt: z.union([z.date(), z.string()]).optional(),
  updatedAt: z.union([z.date(), z.string()]).optional(),
  description: z.string(),
  descriptionOptionSetId: z.string().nullable(),
});

export const upsertCurrentUserTimeWorkEntryDataSchema = upsertTimeWorkEntryDataSchema.partial({
  userId: true,
});

export const upsertTimeWorkEntryDataSchemaFrontend = upsertCurrentUserTimeWorkEntryDataSchema
  .extend({
    reportedMinutes: z.string(),
    groupRequired: z.boolean(),
  })
  .refine(
    upsertTimeWorkData => validateGroupRequired(upsertTimeWorkData.groupRequired, upsertTimeWorkData.descriptionOptionSetId),
    {
      message: 'Group can not be empty',
      path: ['descriptionOptionSetId'],
    },
  )
  .refine(
    upsertTimeWorkData => validateDescription(upsertTimeWorkData.groupRequired, upsertTimeWorkData.description),
    {
      message: 'Comment can not be empty',
      path: ['description'],
    },
  );


export type TUpsertTimeWorkEntryData = z.infer<typeof upsertTimeWorkEntryDataSchema>;
export type TUpsertCurrentUserTimeWorkEntryData = z.infer<
  typeof upsertCurrentUserTimeWorkEntryDataSchema
>;

export type TUpsertCurrentUserTimeWorkEntryDataFrontend = z.infer<
  typeof upsertTimeWorkEntryDataSchemaFrontend
>;

export const upsertTimeWorkEntryDataAdminSchemaFrontend = upsertCurrentUserTimeWorkEntryDataSchema
  .extend({
    reportedMinutes: z.string(),
    groupRequired: z.boolean(),
    userId: z.string().uuid(),
  })
  .refine(
    upsertTimeWorkData => validateGroupRequired(upsertTimeWorkData.groupRequired, upsertTimeWorkData.descriptionOptionSetId),
    {
      message: 'Group can not be empty',
      path: ['descriptionOptionSetId'],
    },
  )
  .refine(
    upsertTimeWorkData => validateDescription(upsertTimeWorkData.groupRequired, upsertTimeWorkData.description),
    {
      message: 'Comment can not be empty',
      path: ['description'],
    },
  );
