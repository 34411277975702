import { LinkProps } from './Link.types';
import NextLink from 'next/link';
import classNames from 'classnames';

export const Link = ({ className, href = '#', ...rest }: LinkProps) => {
  return (
    <NextLink
      href={href}
      className={classNames(
        'cursor-pointer text-sm text-primary-500 hover:text-primary-600 focus:text-primary-300',
        className
      )}
      {...rest}
    />
  );
};
