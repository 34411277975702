import { deleteCookie, getCookies } from 'cookies-next';
import { GetServerSidePropsContext, GetServerSidePropsResult, PreviewData } from 'next';

import {
  IRuleDto,
  TMe,
  USER_ARCHIVED_EXCEPTION_MESSAGE,
  isAxiosResponseError,
} from '@hub/contracts';

import { ParsedUrlQuery } from 'querystring';
import { BasicHttpClient } from './BasicHttpClient';
import { ServerHttpClient } from './ServerHttpClient';
import { UserApi } from './UserApi';
import { IHttpClient, IHttpClientOptions } from './cognito-auth.types';

export type ExtendedGetServerSideProps<
  P extends { [key: string]: any } = { [key: string]: any },
  Q extends ParsedUrlQuery = ParsedUrlQuery,
  D extends PreviewData = PreviewData,
> = (
  context: GetServerSidePropsContext<Q, D>,
) => Promise<GetServerSidePropsResult<P>>;

export function withSession(
  httpClientFactory: (options: IHttpClientOptions) => IHttpClient,
  runWithAmplifyServerContext: any,
  {
    userApiFactory,
    notAuthenticatedRedirectTo,
    skipTenantIdCheck,
  }: {
    userApiFactory?: (httpClient: IHttpClient) => {
      getCurrentUser: () => Promise<TMe>;
      getCurrentUserAbilities: () => Promise<IRuleDto[]>;
    };
    notAuthenticatedRedirectTo: string;
    skipTenantIdCheck?: boolean;
  },
) {
  return (
    getServerSideProps: ExtendedGetServerSideProps = async () => ({
      props: {},
    }),
  ) => {
    return async (ctx: GetServerSidePropsContext) => {
      const { accessToken, tenantId } = await ServerHttpClient.getOptionsFromRequest(ctx, runWithAmplifyServerContext);
      const cfg = BasicHttpClient.getHeaders({ accessToken, tenantId });

      const isAuthenticated = !!accessToken;

      if (!isAuthenticated) {
        ServerHttpClient.deleteJWTCookie({ req: ctx.req, res: ctx.res });
        return redirectTo(ctx, notAuthenticatedRedirectTo);
      }

      const enforceTenantIdCheck = !skipTenantIdCheck;
      if (!tenantId && enforceTenantIdCheck) {
        return redirectTo(ctx, '/customauth/choose-tenant');
      }

      if (userApiFactory) {
        const httpClient = await ServerHttpClient.fromRequest(ctx, runWithAmplifyServerContext, httpClientFactory);
        const userApi = UserApi.getInstance(httpClient);
        try {
          const meApiData = await userApi.getCurrentUser(cfg);
          const meApiDataAbilities = await userApi.getCurrentUserAbilities(cfg);
          const result = await getServerSideProps(ctx);

          if (!('props' in result)) return result;

          return {
            ...result,
            props: {
              ...result.props,
              currentUser: meApiData,
              currentUserAbilities: meApiDataAbilities,
            },
          };
        } catch (error) {
          if (
            isAxiosResponseError(error) &&
            error.response.status === 403 &&
            error.response.data?.message === USER_ARCHIVED_EXCEPTION_MESSAGE
          ) {
            const cookies = getCookies(ctx);
            for (const cookie of Object.keys(cookies)) {
              deleteCookie(cookie, ctx);
            }

            return redirectTo(ctx, '/');
          }

          if (isAxiosResponseError(error) && error.response.status === 404) {
            return {
              notFound: true as const,
            };
          }
          throw error;
        }
      }

      return await getServerSideProps(ctx);
    };
  };
}

function redirectTo(ctx: GetServerSidePropsContext<ParsedUrlQuery>, redirect: string) {
  const redirectLocale = ctx.locale !== ctx.defaultLocale ? `/${ctx.locale}` : '';
  const query = ctx.query;
  const rawParams = Object.entries(query)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return {
    redirect: {
      destination: `${redirectLocale}${redirect}?${rawParams}`,
      permanent: false,
    },
  };
}
