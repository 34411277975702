import { z } from 'zod';
import { userReportStatisticSchema } from './user-report-statistic';

export const employeeWorkingTimeSummarySchema = z.object({
  userId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  contract: z.string(),
  userReportStatistics: z.array(userReportStatisticSchema),
  comment: z.string(),
  approved: z.boolean(),
  hasWarning: z.boolean(),
});

export type TEmployeeWorkingTimeSummary = z.infer<typeof employeeWorkingTimeSummarySchema>;
