import { z } from 'nestjs-zod/z';

const sortBySchema = z.object({
  sortBy: z.array(z.tuple([z.string(), z.string()])).optional(),
});

const sortByStringSchema = z.object({
  sortBy: z.string().optional(),
});

export const paginateQueryWithoutSortingSchema = z.object({
  page: z.number().optional(),
  limit: z.number().optional(),
  searchBy: z.array(z.string()).optional(),
  search: z.string().optional(),
  filter: z.record(z.union([z.string(), z.array(z.string())])).optional(),
  path: z.string().optional(),
});

const paginateQuerySchema = paginateQueryWithoutSortingSchema.merge(sortBySchema);
const paginateQueryWithSortByStringSchema =
  paginateQueryWithoutSortingSchema.merge(sortByStringSchema);

export type TPaginateQuery = z.infer<typeof paginateQuerySchema>;
export type TPaginateQueryWithSortByString = z.infer<typeof paginateQueryWithSortByStringSchema>;
