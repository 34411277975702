import { PropsWithChildren, createContext, useContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AppAbility } from '@hub/authorization';
import { useAuth } from '@hub/cognito-auth';
import { buildAbilityFor } from './ability';
import { useRouter } from 'next/router';
import ForbiddenPage from '../../pages/403';
import { routeAbilitiesMap } from '../../utils';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AbilityContext = createContext<AppAbility>(undefined!);

export const AbilityProvider = ({ children }: PropsWithChildren) => {
  const { pathname } = useRouter();
  const routeConfig = routeAbilitiesMap[pathname];
  const { currentUserAbilities } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ability = buildAbilityFor(currentUserAbilities!);
  // @ts-expect-error: Ability can vary based on params
  const canAccess = routeConfig ? ability.can('read', routeConfig) : true;

  if (!canAccess) {
    return (
      <I18nProvider i18n={i18n}>
        <ForbiddenPage />{' '}
      </I18nProvider>
    );
  }

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

/**
 * Usage:
 *
 * ```typescript
 * const ability = useAbility();
 * console.log(ability.can(UserAction.READ, 'User'));
 * ```
 */
export const useAbility = () => {
  const context = useContext(AbilityContext);

  if (context === undefined) {
    throw new Error('useAbility must be used within a AbilityProvider');
  }

  return context;
};

export const Can = createContextualCan(AbilityContext.Consumer);

export default Can;
