import { z } from 'nestjs-zod/z';

export const createPurchaseSchema = z.object({
  productId: z.string(),
  quantity: z.number(),
  successRedirectUrl: z.string().optional(),
  cancelRedirectUrl: z.string().optional()
});

export const purchaseItemsQuantitySchema = z
  .object({
    productId: z.string(),
    quantity: z.number(),
    currentlyOccupiedSeats: z.number().default(1),
  })
  .refine(
    purchaseItemsQuantityData => {
      if (purchaseItemsQuantityData.quantity < purchaseItemsQuantityData.currentlyOccupiedSeats) {
        return false;
      }

      return true;
    },
    {
      message: 'You cannot have less seats than currently occupied amount',
      path: ['quantity'],
    },
  );

export type TCreatePurchase = z.infer<typeof createPurchaseSchema>;
export type TPurchaseItemsQuantity = z.infer<typeof purchaseItemsQuantitySchema>;
