import { z } from 'nestjs-zod/z';

export const slackWebhookSchema = z.object({
  challenge: z.string().optional(),
  event: z.object({
    bot_profile: z.object({}).optional(),
    type: z.string(),
    channel: z.string(),
    user: z.string(),
    text: z.string(),
  }),
  team_id: z.string(),
});

export type TSlackWebhook = z.infer<typeof slackWebhookSchema>;
