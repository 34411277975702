import { GoogleReCaptchaProvider } from '@hub/captcha-utils';
import { AuthConfigProvider } from '@hub/next-utils';
import { z, ZodErrorMap } from 'nestjs-zod/z';
import { ReactNode } from 'react';
import { customErrorMap } from '../../utils/zodErrorMap';
import { AppConfig } from './AppConfig';
import { useRouter } from 'next/router';

z.setErrorMap(customErrorMap as ZodErrorMap);

interface IAppConfigProviderProps {
  config: AppConfig;
  children: ReactNode;
}
export const AppConfigProvider = ({ config, children }: IAppConfigProviderProps) => {
  const router = useRouter();
  const { cognitoConfig, captchaConfig } = config;
  const isCustomAuthRoutes = router.route.includes('/customauth/');

  return (
    <AuthConfigProvider config={cognitoConfig}>
      {/* Up to developer if it's better to use globally or per page */}
      {isCustomAuthRoutes ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={captchaConfig.captchaPublicKey}
          scriptProps={{
            async: true,
            defer: true,
          }}
        >
          {children}
        </GoogleReCaptchaProvider>
      ) : (
        <>{children}</>
      )}
    </AuthConfigProvider>
  );
};
