import { i18n } from '@lingui/core';
import format from 'string-template';
import { ZodErrorMap, ZodIssueCode, ZodParsedType } from 'zod';

// This is fork of zod-18n library that uses i18next under the hood
// We needed lingui
// Original source code: https://github.com/aiji42/zod-i18n/blob/main/packages/core/src/index.ts

const jsonStringifyReplacer = <T>(_: string, value: T): T | string => {
  if (typeof value === 'bigint') {
    return value.toString();
  }
  return value;
};

function joinValues<T extends unknown[]>(array: T, separator = ' | '): string {
  return array.map(val => (typeof val === 'string' ? `'${val}'` : val)).join(separator);
}

export const customErrorMap: ZodErrorMap = (issue, ctx) => {
  let message: string = ctx.defaultError;
  let values: any = {}

  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = i18n._({ id: 'errors.invalid_type_received_undefined' });
      } else {
        const expected = i18n._({
          id: `types.${issue.expected}`,
        });
        const received = i18n._({
          id: `types.${issue.received}`,
        });
        values = {
          expected,
          received,
        }
        message = i18n._({
          id: 'errors.invalid_type',
          values
        });

      }

      break;
    case ZodIssueCode.invalid_literal:
      values = { expected: JSON.stringify(issue.expected, jsonStringifyReplacer) }
      message = i18n._({
        id: 'errors.invalid_literal',
        values
      });

      break;
    case ZodIssueCode.unrecognized_keys:
      values = {
        keys: joinValues(issue.keys, ', '),
        count: issue.keys.length,
      }
      message = i18n._({
        id: 'errors.unrecognized_keys',
        values
      });

      break;
    case ZodIssueCode.invalid_union:
      message = i18n._({
        id: 'errors.invalid_union',
      });
      break;
    case ZodIssueCode.invalid_union_discriminator:
      values = { options: joinValues(issue.options) }
      message = i18n._({
        id: 'errors.invalid_union_discriminator',
        values
      });

      break;
    case ZodIssueCode.invalid_enum_value:
      values = {
        options: joinValues(issue.options),
        received: issue.received,
      }
      message = i18n._({
        id: 'errors.invalid_enum_value',
        values
      });

      break;
    case ZodIssueCode.invalid_arguments:
      message = i18n._({
        id: 'errors.invalid_arguments',
      });
      break;
    case ZodIssueCode.invalid_return_type:
      message = i18n._({
        id: 'errors.invalid_return_type',
      });
      break;
    case ZodIssueCode.invalid_date:
      message = i18n._({
        id: 'errors.invalid_date',
      });
      break;
    case ZodIssueCode.invalid_string:
      if (typeof issue.validation === 'object') {
        if ('startsWith' in issue.validation) {
          values = {
            startsWith: issue.validation.startsWith,
          }
          message = i18n._({
            id: `errors.invalid_string.startsWith`,
            values
          });
        } else if ('endsWith' in issue.validation) {
          values = {
            endsWith: issue.validation.endsWith,
          };
          message = i18n._({
            id: `errors.invalid_string.endsWith`,
            values
          });

        }
      } else {
        const validation = i18n._({
          id: `validations.${issue.validation}`,
        });
        values = {
          validation
        }

        message = i18n._({
          id: `errors.invalid_string.${issue.validation}`,
          values
        });

      }
      break;
    case ZodIssueCode.too_small: {
      const minimum = issue.type === 'date' ? new Date(issue.minimum as number) : issue.minimum;
      values = { minimum };
      message = i18n._({
        id: `errors.too_small.${issue.type}.${issue.exact ? 'exact' : issue.inclusive ? 'inclusive' : 'not_inclusive'}`,
        values
      });

      break;
    }
    case ZodIssueCode.too_big: {
      const maximum = issue.type === 'date' ? new Date(issue.maximum as number) : issue.maximum;
      values = {
        maximum
      }
      message = i18n._({
        id: `errors.too_big.${issue.type}.${issue.exact ? 'exact' : issue.inclusive ? 'inclusive' : 'not_inclusive'}`,
        values
      });


      break;
    }
    case ZodIssueCode.custom:
      values = issue.params?.values
      message = i18n._({
        id: issue.params?.i18n ?? 'errors.custom',
        values
      });

      break;
    case ZodIssueCode.invalid_intersection_types:
      message = i18n._({ id: 'errors.invalid_intersection_types' });
      break;
    case ZodIssueCode.not_multiple_of:
      values = { multipleOf: issue.multipleOf }
      message = i18n._({
        id: 'errors.not_multiple_of',
        values
      });

      break;
    case ZodIssueCode.not_finite:
      message = i18n._({
        id: 'errors.not_finite',
      });
      break;
    default:
  }
  message = format(message, values);

  return { message };
};
