import { IRuleDto, TMe } from '@hub/contracts';
import { AxiosRequestConfig } from 'axios';
import { IHttpClient } from './cognito-auth.types';

export class UserApi {
  private static instance: UserApi;
  baseUrl = '/api/v2/users';
  private client: IHttpClient;

  constructor(client: IHttpClient) {
    this.client = client;
  }

  getCurrentUser(cfg: AxiosRequestConfig): Promise<TMe> {
    return this.client.get(`${this.baseUrl}/me/`, cfg);
  }

  getCurrentUserAbilities(cfg: AxiosRequestConfig): Promise<IRuleDto[]> {
    return this.client.get(`/api/me/abilities/`, cfg);
  }

  public static getInstance(client: IHttpClient): UserApi {
    if (!UserApi.instance) {
      UserApi.instance = new UserApi(client);
    }

    return UserApi.instance;
  }
}
