import { z } from 'nestjs-zod/z';

export const facebookLoginSchema = z.object({
  code: z.string().min(1),
});

export const facebookMobileLoginSchema = z.object({
  accessToken: z.string().min(1),
});

export const facebookLoginResponseSchema = z.object({
  token: z.string(),
  email: z.string(),
});

export type TFacebookLogin = z.infer<typeof facebookLoginSchema>;
export type TFacebookMobileLogin = z.infer<typeof facebookMobileLoginSchema>;
export type TFacebookLoginResponse = z.infer<typeof facebookLoginResponseSchema>;
